<template>
  <div class="section-wrapper">
    <SolarComponent
      ref="solarRef"
      :solarGroupDetails="solarGroupDetails"
      :hideAreas="hideAreas"
      :showAreas="showAreas"
      :hideSingleArea="hideSingleArea"
      :showSingleArea="showSingleArea"
      :sample="sample"
      :updateTransparencyLevel="updateTransparencyLevel"
      @updateSelectedArea="$emit('updateSelectedArea', $event)"
      @setColapsedSections="setColapsedSections"
      :config="config"
      :setVisibilityInStore="setVisibilityInStore"
      :objectIsloaded="objectIsloaded"
      :removeSolarGroup="removeSolarGroup"
      :unselectSolarArea="unselectSolarArea"
      :anonymousUser="anonymousUser"
      :totalNumberOfPanels="totalNumberOfPanels"
      :totalPanelsArea="totalPanelsArea"
      :active="active"
      class="section"
    />
    <DistanceComponent
      ref="distanceRef"
      :distancesDetails="distancesDetails"
      :hideMeasurements="hideMeasurements"
      :showMeasurements="showMeasurements"
      :sample="sample"
      @setColapsedSections="setColapsedSections"
      @updateSelectedDistance="$emit('updateSelectedDistance', $event)"
      :config="config"
      :setVisibilityInStore="setVisibilityInStore"
      :objectIsloaded="objectIsloaded"
      :deleteMeasurement="deleteMeasurement"
      :anonymousUser="anonymousUser"
      class="section"
    />
    <AreaComponent
      ref="areaRef"
      :areaDetails="areaDetails"
      :hideAllMeasurementAreas="hideAllMeasurementAreas"
      :showAllMeasurementAreas="showAllMeasurementAreas"
      :selectAreaFromSidebar="selectAreaFromSidebar"
      @setColapsedSections="setColapsedSections"
      :sample="sample"
      :config="config"
      :setVisibilityInStore="setVisibilityInStore"
      :objectIsloaded="objectIsloaded"
      :selectedFromSidebarEvent="selectedFromSidebarEvent"
      :removeSelectedMeasurementArea="removeSelectedMeasurementArea"
      :detectedMeasurementArea="detectedMeasurementArea"
      :unselectFromSidebar="unselectFromSidebar"
      :anonymousUser="anonymousUser"
      class="section"
    />
  </div>
</template>

<script setup>
import API from "@/api/API";

import SolarComponent from "@/components/NewSolarSidebar/SolarComponent.vue";
import DistanceComponent from "@/components/NewSolarSidebar/DistanceComponent.vue";
import AreaComponent from "@/components/NewSolarSidebar/AreaComponent.vue";
import { ref, computed, onMounted } from "vue";

import { useStore } from "vuex";

// Define refs for the components
const solarRef = ref(null);
const distanceRef = ref(null);
const areaRef = ref(null);

const store = useStore();

const props = defineProps([
  "solarGroupDetails",
  "distancesDetails",
  "areaDetails",
  "hideAreas",
  "showAreas",
  "hideAllMeasurementAreas",
  "showAllMeasurementAreas",
  "hideMeasurements",
  "showMeasurements",
  "sample",
  "updateTransparencyLevel",
  "selectAreaFromSidebar",
  "hideSingleArea",
  "showSingleArea",
  "measurementAreaCreated",
  "projectId",
  "objectIsloaded",
  "deleteMeasurement",
  "selectedFromSidebarEvent",
  "removeSelectedMeasurementArea",
  "removeSolarGroup",
  "detectedMeasurementArea",
  "unselectFromSidebar",
  "unselectSolarArea",
  "anonymousUser",
  "totalNumberOfPanels",
  "totalPanelsArea",
  "active",
  "selectedMeasurementArea",
  "unselectMeasurementArea",
]);
const emits = defineEmits(["updateActive"]);

// Access current config
const config = computed(() => store.state.section.config);

const setVisibilityInStore = async (property, isVisible) => {
  store.dispatch("section/setConfig", {
    ...config.value,
    [property]: isVisible,
  });
  // here we have to send it to backend

  const projectConfig = await API.airteam3DViewer.putProjectConfig(
    props.projectId,
    config.value
  );
};

function setConfig(config) {
  store.dispatch("section/setConfig", {
    ...config,
  });
}

// get config on mounted and set store
onMounted(async () => {
  const projectConfig = await API.airteam3DViewer.getProjectConfig(
    props.projectId
  );
  setConfig(projectConfig?.data);
});

function handleModeChange(value) {
  switch (value) {
    case 1:
      if (distanceRef.value && distanceRef.value.showDistanceFlag) {
        distanceRef.value.showDistanceFlag();
      } else {
        console.error("showDistanceFlag is not available.");
      }
      break;
    case 2:
      if (solarRef.value && solarRef.value.showSolarFlag) {
        solarRef.value.showSolarFlag();
      } else {
        console.error("showSolarFlag is not available.");
      }
      break;
    case 4:
      if (areaRef.value && areaRef.value.showMeasurementAreaFlag) {
        areaRef.value.showMeasurementAreaFlag();
      } else {
        console.error("showMeasurementAreaFlag is not available.");
      }
      break;
    default:
      console.warn(`Unknown mode value: ${value}`);
  }
}

// let colapsed = null;

function handleComponentCreation(component) {
  switch (component) {
    case "solar":
      if (solarRef.value && solarRef.value.handleCreateSolarGroup) {
        solarRef.value.handleCreateSolarGroup();
      } else {
        console.error("handleCreateSolarGroup is not available.");
      }
      break;
    case "distance":
      if (distanceRef.value && distanceRef.value.handleCreateDistance) {
        distanceRef.value.handleCreateDistance();
      } else {
        console.error("handleCreateDistance is not available.");
      }
      break;
    case "area":
      if (areaRef.value && areaRef.value.handleCreateAreaMeasurement) {
        areaRef.value.handleCreateAreaMeasurement();
      } else {
        console.error("handleCreateAreaMeasurement is not available.");
      }
      break;
    default:
      console.warn(`Unknown component type: ${component}`);
  }
}
function selectMeasurementFromCanvas(distanceIndex) {
  if (distanceRef.value) {
    distanceRef.value.selectMeasurementFromCanvas(distanceIndex);
  }
}
function deselectSidebarDistances() {
  if (distanceRef.value) {
    distanceRef.value.deselectSidebarDistances();
  }
}
function selectAreaOnSidebar(selectedSolarArea) {
  props.unselectMeasurementArea(props.selectedMeasurementArea);
  if (solarRef.value) {
    solarRef.value.selectAreaOnSidebar(selectedSolarArea);
  }
}
function unselectSolarAreaOnSidebar() {
  if (solarRef.value) {
    solarRef.value.unselectSolarAreaOnSidebar();
  }
}
defineExpose({
  handleComponentCreation,
  selectAreaOnSidebar,
  unselectSolarAreaOnSidebar,
  selectMeasurementFromCanvas,
  deselectSidebarDistances,

  handleModeChange,
});

function setColapsedSections(componentName) {
  switch (componentName) {
    case "distanceComponent":
      props.unselectMeasurementArea(props.selectedMeasurementArea);
      if (solarRef.value) {
        solarRef.value.colapseFromParent();
      }
      if (areaRef.value) {
        areaRef.value.colapseFromParent();
      }
      break;
    case "areaComponent":
      props.unselectMeasurementArea(props.selectedMeasurementArea);
      if (solarRef.value) {
        solarRef.value.colapseFromParent();
      }
      if (distanceRef.value) {
        distanceRef.value.colapseFromParent();
      }
      break;
    case "solarComponent":
      if (distanceRef.value) {
        distanceRef.value.colapseFromParent();
      }
      if (areaRef.value) {
        areaRef.value.colapseFromParent();
      }
      break;
    default:
      console.warn(`Unknown component name: ${componentName}`);
  }
}
</script>


<style scoped lang="scss">
.section-wrapper {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  .section {
    background: #333537;
    border-radius: 10px;
    padding: 0 10px;
  }
}
</style>
