import * as THREE from "three";
import API from "@/api/API";
import {
  BLACK,
  VERTICAL,
  HORIZONTAL,
  RENDERING_ORDER,
  TEST_COMPANIES,
  MIDPOINT_COLOR,
  RESTRICTED_AREA_COLOR,
  SELECTED_LINE_COLOR,
  OUTLINR_COLOR,
} from "../constants";
import { isPanelSmall } from "@/utils/panel-size.js";
import { roundVector } from "@/utils/round-vector.js";
import { InstancedMesh2 } from "@three.ez/instanced-mesh";
import { CSS2DObject } from "three/addons/renderers/CSS2DRenderer.js";
import * as turf from "@turf/turf";
import { event } from "vue-gtag";
import { isProduction, isStaging } from "@/utils/env";
import { v4 as uuidv4 } from "uuid";

export const cleanPanelSetup = function () {
  this.panelAdditionType = null;
  this.panelType = null;
  this.panelStep = 0;
  this.panelSpacing = null;
  this.panelSetupConfirmed = false;
};

export const getPanelArea = function (area) {
  let totalArea = 0;
  for (let panel of area.panels) {
    totalArea += panel.size.width * panel.size.height;
  }
  return totalArea.toFixed(2);
};

export const removePanels = async function (panelsToRemove, area) {
  const panelPromises = [];
  for (let panelToRemove of panelsToRemove) {
    const panelObject = this.scene.getObjectById(panelToRemove.plane.id);
    area.panels = area.panels.filter(
      (panel) => panel.plane.id !== panelToRemove.plane.id
    );
    panelObject.visible = false;

    panelPromises.push(panelToRemove.id);
  }

  const panelArrays = chunkArray(panelPromises, 50);
  const panelArrayPromises = panelArrays.map((array) =>
    this.deleteBulkPanelObjects(array)
  );
  Promise.all(panelArrayPromises);

  this.undoStack.push({
    action: "DELETE_PANELS",
    panels: panelsToRemove,
    area: this.selectedArea,
  });
  this.resetRedoStack();
};

export const hidePanels = async function (panelsToRemove, area) {
  const panelPromises = [];
  for (let panelToRemove of panelsToRemove) {
    const panelObject = this.scene.getObjectById(panelToRemove.plane.id);
    area.panels = area.panels.filter(
      (panel) => panel.plane.id !== panelToRemove.plane.id
    );
    panelObject.visible = false;
    panelPromises.push(this.deletePanelObject(panelToRemove.id));
  }

  Promise.all(panelPromises);
};

export const deleteSelectedPanel = function (event) {
  const key = event.key;
  if (key === "Delete" || key === "Backspace") {
    const selectedPanels = this.selectedArea.panels.filter(
      (panel) => panel.selected
    );
    if (selectedPanels.length > 0)
      this.removePanels(selectedPanels, this.selectedArea);
  }
};

export const projectVectorOntoPlane = function (vector, normal) {
  return vector.clone().sub(normal.clone().multiplyScalar(vector.dot(normal)));
};

export const getCameraVector = function (vector) {
  vector.applyQuaternion(this.camera.quaternion);
  return vector;
};

export const findIntersectionBetweenLines = function (
  line1Start,
  line1End,
  line2Start,
  line2End
) {
  const line1Direction = new THREE.Vector3()
    .subVectors(line1End, line1Start)
    .normalize();
  const line2Direction = new THREE.Vector3()
    .subVectors(line2End, line2Start)
    .normalize();

  const crossProduct = new THREE.Vector3().crossVectors(
    line1Direction,
    line2Direction
  );

  if (crossProduct.lengthSq() === 0) {
    // Lines are parallel, so they do not intersect
    return null;
  }

  const diff = new THREE.Vector3().subVectors(line2Start, line1Start);
  const determinant = crossProduct.lengthSq();

  const t1 = diff.clone().cross(line2Direction).dot(crossProduct) / determinant;
  const t2 = diff.clone().cross(line1Direction).dot(crossProduct) / determinant;

  const pointOnLine1 = line1Start
    .clone()
    .add(line1Direction.clone().multiplyScalar(t1));
  const pointOnLine2 = line2Start
    .clone()
    .add(line2Direction.clone().multiplyScalar(t2));

  // Check if the intersection points on both lines are the same (i.e., lines truly intersect)
  if (pointOnLine1.distanceTo(pointOnLine2) < 1e-6) {
    return pointOnLine1;
  }

  return pointOnLine1;
};

export const calculateProjectedPoint = function (
  xPoint,
  yPoint,
  xDirection,
  yDirection,
  xPositive,
  yPositive
) {
  const xLineEnd = xPoint
    .clone()
    .add(xDirection.multiplyScalar(xPositive ? 50 : -50));
  const yLineEnd = yPoint
    .clone()
    .add(yDirection.multiplyScalar(yPositive ? 50 : -50));

  const intersectionPoint = this.findIntersectionBetweenLines(
    xPoint,
    xLineEnd,
    yPoint,
    yLineEnd
  );

  return intersectionPoint;
};

export const getSimulatedCamera = function (center, normal) {
  const simulatedCamera = this.camera.clone();
  const cameraPosition = center.clone().add(normal.clone().multiplyScalar(15));
  simulatedCamera.position.copy(cameraPosition);
  simulatedCamera.lookAt(center);
  simulatedCamera.updateMatrixWorld();

  return simulatedCamera;
};

export const getAxisDirectionInPlane = function (
  simulatedCamera,
  axis,
  normal
) {
  const simulatedCameraVector = axis
    .clone()
    .applyQuaternion(simulatedCamera.quaternion.clone());

  const axisDirectionInPlane = projectVectorOntoPlane(
    simulatedCameraVector,
    normal
  ).normalize();

  return axisDirectionInPlane;
};

export const calculatePanelPlacement = function ({
  startPos,
  columns,
  rows,
  verticalVector,
  horizontalVector,
  verticalScalar,
  horizontalScalar,
  normal,
  ground,
  offset,
  panel,
  areaPoints,
  areaEdges,
  simulatedCamera,
}) {
  const placementId = uuidv4();

  const grid = [];
  let visiblePanels = 0;

  for (let i = -2; i <= rows + 1; i++) {
    const verticalOffset = verticalVector
      .clone()
      .multiplyScalar(verticalScalar * i);

    for (let j = -2; j <= columns + 1; j++) {
      const horizontalOffset = horizontalVector
        .clone()
        .multiplyScalar(horizontalScalar * j);

      let pos = startPos.clone().sub(verticalOffset).add(horizontalOffset);

      const { panelVertices, panelEdges } = this.createPanelAt({
        panel,
        pos,
        ground,
        normal,
      });

      if (
        this.isRectangleWithinPoints(
          panelVertices,
          panelEdges,
          areaPoints,
          areaEdges,
          simulatedCamera
        )
      ) {
        visiblePanels++;
      }
      pos = pos.sub(offset);
      grid.push(pos);
    }
  }

  return { id: placementId, count: visiblePanels, grid };
};

export const calculatePanelPlacementFromCenter = function ({
  startPos,
  stepX,
  stepY,
  normal,
  ground,
  offset,
  panel,
  areaPoints,
  areaEdges,
  simulatedCamera,
}) {
  const placementId = uuidv4();

  let addedPanels = [];

  let pos = startPos.clone();
  const { panelVertices, panelEdges } = this.createPanelAt({
    panel,
    pos,
    ground,
    normal,
  });

  if (
    this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      areaPoints,
      areaEdges,
      simulatedCamera
    )
  ) {
    pos = pos.sub(offset);
    addedPanels.push(pos);

    const zeroVector = new THREE.Vector3(0, 0, 0);
    this.expandInDirection({
      startPos,
      stepX,
      xPositive: true,
      stepY: zeroVector,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
    this.expandInDirection({
      startPos,
      stepX: stepX,
      xPositive: false,
      stepY: zeroVector,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
    this.expandInDirection({
      startPos,
      stepX: zeroVector,
      stepY,
      yPositive: true,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
    this.expandInDirection({
      startPos,
      stepX: zeroVector,
      stepY: stepY,
      yPositive: false,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
  }

  return { id: placementId, grid: addedPanels, count: addedPanels.length };
};

export const createPanelAt = function ({
  panel,
  pos,
  rotation,
  ground,
  normal,
}) {
  panel.position.copy(pos);
  panel.up.copy(ground);
  panel.lookAt(pos.clone().add(normal));

  if (rotation) {
    panel.setRotationFromQuaternion(rotation);
  }

  const geometry = panel.geometry.clone();
  panel.updateMatrix();
  panel.updateWorldMatrix();
  geometry.applyMatrix4(panel.matrixWorld);

  const flatPanelVertices = geometry.attributes.position.array;
  const panelVertices = this.flatArrayToVectors3D(flatPanelVertices);

  const indices = geometry.index.array;
  const panelEdges = [];
  for (let i = 0; i < indices.length; i++) {
    const edge = [
      panelVertices[indices[i]],
      panelVertices[indices[(i + 1) % indices.length]],
    ];
    panelEdges.push(edge);
  }

  return { panelVertices: panelVertices, panelEdges: panelEdges, indices };
};

export const expandInDirection = function ({
  startPos,
  stepX,
  xPositive,
  stepY,
  yPositive,
  panel,
  ground,
  normal,
  areaPoints,
  areaEdges,
  simulatedCamera,
  offset,
  addedPanels,
}) {
  let pos = startPos.clone();

  if (yPositive) pos = pos.add(stepY);
  else pos = pos.sub(stepY);

  if (xPositive) pos = pos.add(stepX);
  else pos = pos.sub(stepX);

  const { panelVertices, panelEdges } = this.createPanelAt({
    panel,
    pos,
    ground,
    normal,
  });

  if (
    this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      areaPoints,
      areaEdges,
      simulatedCamera
    )
  ) {
    addedPanels.push(pos.clone().sub(offset));

    this.expandInDirection({
      startPos: pos,
      stepX,
      xPositive,
      stepY,
      yPositive,
      panel,
      ground,
      normal,
      areaPoints,
      areaEdges,
      simulatedCamera,
      offset,
      addedPanels,
    });
  }
};

export const normalTowardsCamera = function (position, normal) {
  const normalCopy = normal.clone();
  const toCamera = new THREE.Vector3()
    .subVectors(this.camera.position, position)
    .normalize();
  if (normalCopy.dot(toCamera) > 0) {
    normalCopy.negate();
  }
  return normalCopy;
};

export const differenceWithinRange = function (position_1, position_2) {
  const { x, y, z } = position_1;

  const xDifference = Math.abs(x - position_2.x);
  const yDifference = Math.abs(y - position_2.y);
  const zDifference = Math.abs(z - position_2.z);
  if (xDifference < 0.1 && yDifference < 0.1 && zDifference < 0.1) {
    return true;
  }
};

export const normalNeedsFlipping = function (position, normal) {
  const positionWithOffset = position
    .clone()
    .sub(normal.clone().multiplyScalar(0.1));

  const rayDirection = normal.clone().normalize();
  this.raycaster.set(positionWithOffset, rayDirection);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length === 0) return true;
  return false;
};

export const populateArea = async function (
  area,
  persist = true,
  forceFlipNormal = false,
  areaDragged = false
) {
  const vectorPoints = area.points.map((point) => point.position);
  const isAreaInFrontOfModel = this.isAreaInFrontOfModel(areaDragged);

  let normal = this.calculatePlaneNormal(vectorPoints);
  if (forceFlipNormal || !isAreaInFrontOfModel) normal.negate();

  const center = this.getCenterPointFromVectors(vectorPoints);
  const simulatedCamera = this.getSimulatedCamera(center, normal);

  const points = this.getInnerPlanePoints(area, normal, simulatedCamera);

  const innerPlane = this.createInnerPlane(points, area.stencilCount);
  area.innerPlane = innerPlane;
  this.scene.add(innerPlane);

  const innerPlaneEdges = [];
  for (let i = 0; i < points.length; i++) {
    const nextIndex = (i + 1) % points.length;
    innerPlaneEdges.push([points[i], points[nextIndex]]);
  }
  innerPlane.edges = innerPlaneEdges;

  const chosenPanel = this.getPanelById(area.panelId, area.orientation);
  const panelTexture = chosenPanel.texture;
  const panelWidth = chosenPanel.size.width;
  const panelHeight = chosenPanel.size.height;
  const panelSpacingX = area.horizontalSpacing / 100;
  const panelSpacingY = area.verticalSpacing / 100;

  const rightMostPoint = this.getRightMostPoint(points, simulatedCamera);
  const leftMostPoint = this.getLeftMostPoint(points, simulatedCamera);
  const topMostPoint = this.getTopMostPoint(points, simulatedCamera);
  const bottomMostPoint = this.getBottomMostPoint(points, simulatedCamera);

  const areaWidth = leftMostPoint.distanceTo(rightMostPoint);
  const areaHeight = bottomMostPoint.distanceTo(topMostPoint);
  const columns = Math.floor(areaWidth / (panelWidth + panelSpacingX));
  const rows = Math.floor(areaHeight / (panelHeight + panelSpacingY));

  const xAxis = new THREE.Vector3(1, 0, 0);
  const xDirectionInPlane = this.getAxisDirectionInPlane(
    simulatedCamera,
    xAxis,
    normal
  );

  const yAxis = new THREE.Vector3(0, 1, 0);
  const yDirectionInPlane = this.getAxisDirectionInPlane(
    simulatedCamera,
    yAxis,
    normal
  );

  const projectedTopLeftPoint = this.calculateProjectedPoint(
    topMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    true
  );

  const projectedTopRightPoint = this.calculateProjectedPoint(
    topMostPoint,
    rightMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    true,
    true
  );
  const projectedBottomLeftPoint = this.calculateProjectedPoint(
    bottomMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    false
  );

  const horizontalVector = projectedTopRightPoint
    .clone()
    .sub(projectedTopLeftPoint)
    .normalize();

  const verticalVector = projectedTopLeftPoint
    .clone()
    .sub(projectedBottomLeftPoint)
    .normalize();

  let startPos = projectedTopLeftPoint.clone();

  const verticalScalar = panelHeight + panelSpacingY;
  const horizontalScalar = panelWidth + panelSpacingX;

  const testPanel = this.createTempPanel(chosenPanel);

  const offset = normal.clone().multiplyScalar(area.offset / 100);

  const placementIterations = [
    roundVector(startPos),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
    ),
    roundVector(
      startPos
        .clone()
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
  ];

  let optimalPlacement = [];
  if (persist) {
    const placementIterationsResults = placementIterations.map((startPos) => {
      return this.calculatePanelPlacement({
        startPos,
        rows,
        columns,
        verticalVector,
        horizontalVector,
        verticalScalar,
        horizontalScalar,
        normal,
        ground: verticalVector,
        offset,
        panel: testPanel,
        areaPoints: points,
        areaEdges: innerPlaneEdges,
        simulatedCamera,
      });
    });

    const stepX = horizontalVector.clone().multiplyScalar(horizontalScalar);
    const stepY = verticalVector.clone().multiplyScalar(verticalScalar);
    const centerPlacementResult = this.calculatePanelPlacementFromCenter({
      startPos: center,
      stepX,
      stepY,
      normal,
      ground: verticalVector,
      offset,
      panel: testPanel,
      areaPoints: points,
      areaEdges: innerPlaneEdges,
      areaIndices: area.indices,
      simulatedCamera,
    });
    placementIterationsResults.push(centerPlacementResult);
    placementIterations.push(center);

    optimalPlacement = placementIterationsResults.reduce((prev, current) =>
      prev.count > current.count ? prev : current
    );

    const optimalPlacementIndex = placementIterationsResults.findIndex(
      (placement) => placement.id === optimalPlacement.id
    );
    if (optimalPlacement.count > 0) {
      area.startPosition = placementIterations[optimalPlacementIndex];
      area.originalPosition = placementIterations[optimalPlacementIndex];
      area.iteration = this.getPlacementTitle(optimalPlacementIndex);

      if (
        (isProduction && !TEST_COMPANIES.includes(this.companyId)) ||
        isStaging
      ) {
        event("panel_placement", {
          project_id: this.projectId,
          placement_index: optimalPlacementIndex,
          placement_title: area.iteration,
          number_of_points: points.length,
          number_of_panels: optimalPlacement.length,
          panel_width: chosenPanel.size.width,
          panel_height: chosenPanel.size.height,
          panel_orientation: area.orientation,
          horizontal_spacing: area.horizontalSpacing,
          vertical_spacing: area.verticalSpacing,
          margin: area.margin,
        });
      }
    }
  } else {
    placementIterations.push(center);

    if (this.normalNeedsFlipping(vectorPoints[0], normal)) {
      if (!forceFlipNormal) {
        this.scene.remove(innerPlane);
        this.populateArea(area, false, true);
        return;
      }
    }
    if (area.originalPosition) {
      if (this.differenceWithinRange(area.originalPosition, center)) {
        const stepX = horizontalVector.clone().multiplyScalar(horizontalScalar);
        const stepY = verticalVector.clone().multiplyScalar(verticalScalar);
        optimalPlacement = this.calculatePanelPlacementFromCenter({
          startPos: area.startPosition,
          stepX,
          stepY,
          normal,
          ground: verticalVector,
          offset,
          panel: testPanel,
          areaPoints: points,
          areaEdges: innerPlaneEdges,
          areaIndices: area.indices,
          simulatedCamera,
        });
      } else {
        optimalPlacement = this.calculatePanelPlacement({
          startPos: area.startPosition,
          rows,
          columns,
          verticalVector,
          horizontalVector,
          verticalScalar,
          horizontalScalar,
          normal,
          ground: verticalVector,
          offset,
          panel: testPanel,
          areaPoints: points,
          areaEdges: innerPlaneEdges,
          areaIndices: area.indices,
          simulatedCamera,
        });
      }
    }
  }

  let instancedMesh;
  let label, rotateLabel;

  if (optimalPlacement.count > 0) {
    instancedMesh = this.createPanelInstance(
      panelWidth,
      panelHeight,
      panelTexture,
      optimalPlacement.grid.length
    );

    this.scene.add(instancedMesh);

    if (!area.moveGridLabel) {
      const moveGridElement = createGridElement();
      moveGridElement.src = "/assets/icons/move-grid.svg";

      const rotateGridElement = createGridElement();
      rotateGridElement.src = "/assets/icons/rotate-grid.svg";

      const centerPoint = this.getCenterPointFromVectors(points);

      const moveLabelOffset = horizontalVector.clone().multiplyScalar(-0.4);
      const moveLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(moveLabelOffset);

      label = this.createGridLabel(moveGridElement, moveLabelPosition);
      this.scene.add(label);
      area.moveGridLabel = label;
      label.labelOffset = moveLabelOffset;

      const rotateLabelOffset = horizontalVector.clone().multiplyScalar(0.4);
      const rotateLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(rotateLabelOffset);

      rotateLabel = this.createGridLabel(
        rotateGridElement,
        rotateLabelPosition
      );
      this.scene.add(rotateLabel);
      rotateLabel.labelOffset = rotateLabelOffset;
      area.rotateGridLabel = rotateLabel;

      moveGridElement.addEventListener("mousedown", this.onDragSolarGroupStart);

      rotateGridElement.addEventListener(
        "mousedown",
        this.onRotateSolarGroupStart
      );
    } else {
      const centerPoint = this.getCenterPointFromVectors(points);

      const label = area.moveGridLabel;
      label.labelOffset = horizontalVector.clone().multiplyScalar(-0.4);
      const moveLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(label.labelOffset);
      label.position.set(
        moveLabelPosition.x,
        moveLabelPosition.y,
        moveLabelPosition.z
      );

      const rotateLabel = area.rotateGridLabel;
      rotateLabel.labelOffset = horizontalVector.clone().multiplyScalar(0.4);
      const rotateLabelPosition = new THREE.Vector3()
        .copy(centerPoint)
        .add(rotateLabel.labelOffset);
      rotateLabel.position.set(
        rotateLabelPosition.x,
        rotateLabelPosition.y,
        rotateLabelPosition.z
      );
    }
  }

  const infinitePlane = new THREE.Plane();
  infinitePlane.setFromNormalAndCoplanarPoint(normal, center);

  area.instancedMesh = instancedMesh;
  area.normal = normal;
  area.ground = verticalVector;
  area.simulatedCamera = simulatedCamera;
  area.testPanel = testPanel;
  area.verticalVector = verticalVector;
  area.horizontalVector = horizontalVector;
  area.horizontalVector = horizontalVector;
  area.infinitePlane = infinitePlane;

  const tempMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempTarget = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  if (instancedMesh) {
    for (let i = 0; i < optimalPlacement.grid.length; i++) {
      const position = optimalPlacement.grid[i];

      tempPosition.set(position.x, position.y, position.z);
      tempTarget.copy(tempPosition).add(normal);

      const tempMatrixLookAt = new THREE.Matrix4().lookAt(
        tempPosition,
        tempTarget,
        verticalVector
      );

      tempQuaternion.setFromRotationMatrix(tempMatrixLookAt);

      tempMatrix.compose(tempPosition, tempQuaternion, tempScale);

      instancedMesh.setMatrixAt(i, tempMatrix);
    }

    let seedIndex;
    if (area.iteration === "CENTER") {
      seedIndex = 0;
    } else {
      seedIndex = 2 * (columns + 4) + 2;
    }

    instancedMesh.getMatrixAt(seedIndex, tempMatrix);
    tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);
    instancedMesh.seed = tempPosition.clone();

    if (area.currentRotation) {
      area.areaCenter = center;

      for (let i = 0; i < optimalPlacement.grid.length; i++) {
        instancedMesh.getMatrixAt(i, tempMatrix);
        tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);

        tempPosition.sub(area.areaCenter);

        const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
          normal,
          area.currentRotation
        );
        tempPosition.applyMatrix4(rotationMatrix);
        tempQuaternion.premultiply(
          new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
        );

        tempPosition.add(area.areaCenter);

        tempMatrix.compose(tempPosition, tempQuaternion, tempScale);
        instancedMesh.setMatrixAt(i, tempMatrix);
      }
    }

    instancedMesh.instanceMatrix.needsUpdate = true;

    this.checkPanelsInSolarArea(area);
  }

  if (persist) {
    this.createUpdateSolarGroup(area);
    this.disableDefaultNavigation();
  }
};

export const removeSolarGroupPanels = function (area) {
  if (area.instancedMesh) {
    const instancedMesh = this.scene.getObjectById(area.instancedMesh.id);
    this.scene.remove(instancedMesh);
    instancedMesh.dispose();
    area.instancedMesh = null;
  }

  this.removeSolarGroupInnerPlane(area);
};

export const removeSolarGroupInnerPlane = function (area) {
  const innerPlane = this.scene.getObjectById(area.innerPlane?.id);
  if (innerPlane) {
    this.scene.remove(innerPlane);
    innerPlane.geometry.dispose();
    innerPlane.material.dispose();
  }
  area.innerPlane = null;
};

export const replaceSolarGroupInnerPlane = function (area) {
  this.removeSolarGroupInnerPlane(area);

  const vectorPoints = area.points.map((point) => point.position);
  let normal = this.calculatePlaneNormal(vectorPoints);

  const center = this.getCenterPointFromVectors(vectorPoints);
  const simulatedCamera = this.getSimulatedCamera(center, normal);

  const points = this.getInnerPlanePoints(area, normal, simulatedCamera);

  const innerPlane = this.createInnerPlane(points, area.stencilCount);
  area.innerPlane = innerPlane;
  this.scene.add(innerPlane);

  const innerPlaneEdges = [];
  for (let i = 0; i < points.length; i++) {
    const nextIndex = (i + 1) % points.length;
    innerPlaneEdges.push([points[i], points[nextIndex]]);
  }
  innerPlane.edges = innerPlaneEdges;

  area.simulatedCamera = simulatedCamera;
  area.normal = normal;
  area.center = center;
};

export const simulatePopulateArea = function (area) {
  const chosenPanel = this.getPanelById(area.panelId, area.orientation);
  const panelWidth = chosenPanel.size.width;
  const panelHeight = chosenPanel.size.height;
  const panelSpacingX = area.horizontalSpacing / 100;
  const panelSpacingY = area.verticalSpacing / 100;

  const rightMostPoint = this.getRightMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );
  const leftMostPoint = this.getLeftMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );
  const topMostPoint = this.getTopMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );
  const bottomMostPoint = this.getBottomMostPoint(
    area.innerPlane.points,
    area.simulatedCamera
  );

  const areaWidth = leftMostPoint.distanceTo(rightMostPoint);
  const areaHeight = bottomMostPoint.distanceTo(topMostPoint);
  const columns = Math.floor(areaWidth / (panelWidth + panelSpacingX));
  const rows = Math.floor(areaHeight / (panelHeight + panelSpacingY));

  const xAxis = new THREE.Vector3(1, 0, 0);
  const xDirectionInPlane = this.getAxisDirectionInPlane(
    area.simulatedCamera,
    xAxis,
    area.normal
  );

  const yAxis = new THREE.Vector3(0, 1, 0);
  const yDirectionInPlane = this.getAxisDirectionInPlane(
    area.simulatedCamera,
    yAxis,
    area.normal
  );

  const projectedTopLeftPoint = this.calculateProjectedPoint(
    topMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    true
  );

  const projectedTopRightPoint = this.calculateProjectedPoint(
    topMostPoint,
    rightMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    true,
    true
  );
  const projectedBottomLeftPoint = this.calculateProjectedPoint(
    bottomMostPoint,
    leftMostPoint,
    xDirectionInPlane,
    yDirectionInPlane,
    false,
    false
  );

  const horizontalVector = projectedTopRightPoint
    .clone()
    .sub(projectedTopLeftPoint)
    .normalize();

  const verticalVector = projectedTopLeftPoint
    .clone()
    .sub(projectedBottomLeftPoint)
    .normalize();

  let startPos = projectedTopLeftPoint.clone();

  const verticalScalar = panelHeight + panelSpacingY;
  const horizontalScalar = panelWidth + panelSpacingX;

  const testPanel = this.createTempPanel(chosenPanel);

  const offset = area.normal.clone().multiplyScalar(area.offset / 100);

  const placementIterations = [
    roundVector(startPos),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
    ),
    roundVector(
      startPos
        .clone()
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
    roundVector(
      startPos
        .clone()
        .sub(verticalVector.clone().multiplyScalar(panelHeight / 2))
        .add(horizontalVector.clone().multiplyScalar(panelWidth / 2))
    ),
  ];

  const placementIterationsResults = placementIterations.map((startPos) => {
    return this.calculatePanelPlacement({
      startPos,
      rows,
      columns,
      verticalVector,
      horizontalVector,
      verticalScalar,
      horizontalScalar,
      normal: area.normal,
      ground: verticalVector,
      offset,
      panel: testPanel,
      areaPoints: area.innerPlane.points,
      areaEdges: area.innerPlane.edges,
      simulatedCamera: area.simulatedCamera,
    });
  });

  const stepX = horizontalVector.clone().multiplyScalar(horizontalScalar);
  const stepY = verticalVector.clone().multiplyScalar(verticalScalar);
  const centerPlacementResult = this.calculatePanelPlacementFromCenter({
    startPos: area.center,
    stepX,
    stepY,
    normal: area.normal,
    ground: verticalVector,
    offset,
    panel: testPanel,
    areaPoints: area.innerPlane.points,
    areaEdges: area.innerPlane.edges,
    areaIndices: area.indices,
    simulatedCamera: area.simulatedCamera,
  });
  placementIterationsResults.push(centerPlacementResult);

  const optimalPlacement = placementIterationsResults.reduce((prev, current) =>
    prev.count > current.count ? prev : current
  );

  let visiblePanels = 0;

  const tempMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempTarget = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  for (let position of optimalPlacement.grid) {
    tempPosition.set(position.x, position.y, position.z);
    tempTarget.copy(tempPosition).add(area.normal);

    const tempMatrixLookAt = new THREE.Matrix4().lookAt(
      tempPosition,
      tempTarget,
      verticalVector
    );

    tempQuaternion.setFromRotationMatrix(tempMatrixLookAt);

    tempMatrix.compose(tempPosition, tempQuaternion, tempScale);

    if (area.currentRotation) {
      tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);
      tempPosition.sub(area.areaCenter);

      const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
        area.normal,
        area.currentRotation
      );
      tempPosition.applyMatrix4(rotationMatrix);
      tempQuaternion.premultiply(
        new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
      );

      tempPosition.add(area.areaCenter);
      tempMatrix.compose(tempPosition, tempQuaternion, tempScale);
    }

    tempMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    if (
      this.panelInsideArea(
        tempPositionWithoutOffset,
        tempQuaternion,
        testPanel,
        verticalVector,
        area.normal,
        area.simulatedCamera,
        area.innerPlane
      )
    ) {
      const { panelVertices, panelEdges } = this.createPanelAt({
        panel: testPanel,
        pos: tempPositionWithoutOffset,
        rotation: tempQuaternion,
        ground: verticalVector,
        normal: area.normal,
      });

      let panelVisible = true;
      for (const restrictedArea of area.restrictedAreas) {
        const restrictedAreaPoints = restrictedArea.points.map(
          (point) =>
            new THREE.Vector3(
              point.position.x,
              point.position.y,
              point.position.z
            )
        );

        if (
          this.isRectangleIntersectingArea(
            panelVertices,
            panelEdges,
            restrictedAreaPoints,
            restrictedArea.edges,
            area.simulatedCamera
          )
        ) {
          panelVisible = false;
        }
      }
      if (panelVisible) visiblePanels++;
    }
  }
  return visiblePanels;
};

export const addOffsetToPanels = function (area, offsetValue) {
  const normal = this.calculatePlaneNormal(area.innerPlane.points);

  const offset = normal.clone().multiplyScalar(offsetValue / 100);

  this.addOffsetToInstancedMesh(area.instancedMesh, offset);
  this.addOffsetToInstancedMesh(area.verticalPanelInstancedMesh, offset);
  this.addOffsetToInstancedMesh(area.horizontalPanelInstancedMesh, offset);
};

export const addOffsetToInstancedMesh = function (instancedMesh, offset) {
  if (!instancedMesh || instancedMesh.instancesCount === 0) return;

  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);

    transformationMatrix.decompose(
      transformationPosition,
      transformationQuaternion,
      transformationScale
    );

    transformationPosition.sub(offset);

    transformationMatrix.compose(
      transformationPosition,
      transformationQuaternion,
      transformationScale
    );
    instancedMesh.setMatrixAt(i, transformationMatrix);
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const getInnerPlanePoints = function (area, normal, camera) {
  const areaPointsVectors = area.points.map((point) => point.position);

  if (area.margin === 0) return areaPointsVectors;

  const lines = [];
  for (let i = 0; i < areaPointsVectors.length; i++) {
    const nextIndex = (i + 1) % areaPointsVectors.length;
    lines.push({
      firstPoint: areaPointsVectors[i],
      secondPoint: areaPointsVectors[nextIndex],
    });
  }

  const translatedLines = lines.map((line) =>
    this.translateLine(
      line,
      normal,
      camera,
      areaPointsVectors,
      area.margin / 100
    )
  );

  const innerPlanePoints = [];

  for (let i = 0; i < translatedLines.length; i++) {
    const line1 = translatedLines[i === 0 ? translatedLines.length - 1 : i - 1];
    const line2 = translatedLines[i];

    const intersectionPoint = this.findIntersectionBetweenLines(
      line1.start,
      line1.end,
      line2.start,
      line2.end
    );
    innerPlanePoints.push(intersectionPoint);
  }

  return innerPlanePoints;
};

export const translateLine = function (
  line,
  normal,
  camera,
  areaPoints,
  margin
) {
  let { translatedFirstPoint, translatedSecondPoint } =
    this.getTranslatedPointsForLine(line, normal, margin);

  const translatedMidpoint = new THREE.Vector3().lerpVectors(
    translatedFirstPoint,
    translatedSecondPoint,
    0.5
  );

  if (!this.isPointWithinArea(translatedMidpoint, areaPoints, camera)) {
    const translatedPoint = this.getTranslatedPointsForLine(
      line,
      normal,
      margin,
      true
    );
    translatedFirstPoint = translatedPoint.translatedFirstPoint;
    translatedSecondPoint = translatedPoint.translatedSecondPoint;
  }

  const traslatedLine = {
    start: translatedFirstPoint,
    end: translatedSecondPoint,
  };

  return traslatedLine;
};

export const getTranslatedPointsForLine = function (
  line,
  normal,
  margin,
  flip = false
) {
  const { firstPoint, secondPoint } = line;
  const direction = new THREE.Vector3().subVectors(firstPoint, secondPoint);
  const perpendicular = new THREE.Vector3()
    .crossVectors(direction, normal)
    .normalize();

  if (flip) perpendicular.negate();

  const translatedFirstPoint = firstPoint
    .clone()
    .addScaledVector(perpendicular, margin);
  const translatedSecondPoint = secondPoint
    .clone()
    .addScaledVector(perpendicular, margin);

  return { translatedFirstPoint, translatedSecondPoint };
};

export const pointIntersectsPlane = function (point, plane, normal) {
  // const cameraPosition = camera.position.clone();
  const pointPosition = point.clone();
  const rayDirection = normal.clone();

  this.raycaster.set(pointPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(plane);
  if (intersects.length > 0) return true;

  const geometry = new THREE.SphereGeometry(0.1, 32, 32);
  const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.copy(point);
  sphere.material.depthTest = false;
  sphere.renderOrder = Infinity;
  this.scene.add(sphere);

  return false;
};

export const createInnerPlane = function (points, stencilCount) {
  const geometry = new THREE.BufferGeometry();

  const pointsAsArrays = points.map((point) => [point.x, point.y, point.z]);

  const flatPoints = [].concat(...pointsAsArrays);

  const vertices = new Float32Array(flatPoints);

  const triangleIndices = this.getTriangleIndices(
    points.map((point) => {
      return {
        position: point,
      };
    }),
    this.getAxisDifferences(points)
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    side: THREE.DoubleSide,
    stencilWrite: true,
    stencilFunc: THREE.AlwaysStencilFunc,
    stencilRef: stencilCount,
    stencilZPass: THREE.ReplaceStencilOp,
    transparent: true,
    opacity: 0.25,
  });

  const plane = new THREE.Mesh(geometry, material);

  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.INNER_SOLAR_PLANE;

  plane.points = points;

  return plane;
};

export const calculatePlaneNormal = function (points) {
  const point1 = points[0];
  const point2 = points[1];
  const point3 = points[2];

  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();

  const cameraToPlane = new THREE.Vector3();
  cameraToPlane.subVectors(point1, this.camera.position).normalize();

  const dotProduct = normal.dot(cameraToPlane);

  if (dotProduct < 0) {
    normal.negate();
  }
  return normal;
};

export const getInPlaneVector = function (normal, edge) {
  const inPlaneVector = new THREE.Vector3()
    .crossVectors(normal, edge)
    .normalize();

  return inPlaneVector;
};

export const getPanelById = function (panelId, orientation) {
  let panels = orientation
    ? this.verticalPanelTypes
    : this.horizontalPanelTypes;
  let panel = panels.find((panel) => panel.id === panelId);
  if (!panel) {
    panels =
      this.customPanels.filter(
        (panel) =>
          panel.orientation === (orientation ? "vertical" : "horizontal")
      ) || [];
    panel = panels.find((panel) => panel.id === panelId);
  }
  if (!panel) panel = panels[0];
  return panel;
};

export const getLongestEdge = function (points) {
  let longestEdge = null;
  let longestEdgeLength = 0;
  for (let i = 0; i < points.length; i++) {
    const edge = new THREE.Vector3().subVectors(
      points[i],
      points[(i + 1) % points.length]
    );
    const edgeLength = edge.length();
    if (edgeLength > longestEdgeLength) {
      longestEdgeLength = edgeLength;
      longestEdge = edge;
    }
  }
  return longestEdge;
};

export const createTrashIcon = function (panel, addCounter = true) {
  const iconMaterial = new THREE.MeshBasicMaterial({
    map: this.closeTexture,
    transparent: true,
  });

  const trashDimension = 0.25 * Math.max(panel.size.width, panel.size.height);

  const iconGeomtery = new THREE.PlaneGeometry(trashDimension, trashDimension);
  const icon = new THREE.Mesh(iconGeomtery, iconMaterial);
  icon.position.set(
    panel.size.width / 2 - trashDimension / 2,
    panel.size.height / 2 - trashDimension / 2,
    0
  );

  icon.material.depthTest = false;
  icon.renderOrder = RENDERING_ORDER.PANEL_TRASH;
  if (addCounter) icon.renderOrder += this.panelCounter;
  icon.visible = false;

  return icon;
};

export const updateTrashIcon = function (icon, panel) {
  const trashDimension = 0.25 * Math.max(panel.size.width, panel.size.height);

  icon.geometry.dispose();
  icon.geometry = new THREE.PlaneGeometry(trashDimension, trashDimension);

  icon.position.set(
    panel.size.width / 2 - trashDimension / 2,
    panel.size.height / 2 - trashDimension / 2,
    0
  );
};

export const flatArrayToVectors3D = function (flatArray) {
  const vectorArray = [];
  for (let i = 0; i < flatArray.length; i = i + 3) {
    vectorArray.push(
      new THREE.Vector3(flatArray[i], flatArray[i + 1], flatArray[i + 2])
    );
  }
  return vectorArray;
};

export const createPanelObject = async function (
  areaId,
  point,
  panelId,
  orientation,
  renderOrder
) {
  if (this.sample) return;
  const panelObject = {
    projectId: Number(this.projectId),
    areaId,
    position: point,
    panelId,
    orientation,
    renderOrder,
  };
  return await API.airteam3DViewer.createPanelObject(panelObject);
};

export const createBulkPanelObjects = async function (array) {
  if (this.sample) return;
  return await API.airteam3DViewer.createBulkPanelObjects(array);
};

export const updatePanelObject = async function (
  id,
  areaId,
  point,
  panelId,
  orientation,
  renderOrder
) {
  if (this.sample) return;
  const panelObject = {
    id,
    projectId: Number(this.projectId),
    areaId,
    position: point,
    panelId,
    orientation,
    renderOrder,
  };
  return await API.airteam3DViewer.updatePanelObject(panelObject);
};

export const updateBulkPanelObjects = async function (array) {
  if (this.sample) return;

  return await API.airteam3DViewer.updateBulkPanelObject(array);
};

export const deletePanelObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const deleteBulkPanelObjects = async function (array) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteBulkPanelObjects(array);
};

export const getDefaultPanelTypes = async function () {
  try {
    const res = await API.airteam3DViewer.getDefaultPanelTypes();
    const defaultPanels = [];

    res.data.forEach((panel) => {
      const originalPanel = {
        ...panel,
        texture: null,
        orientation:
          panel.size.height > panel.size.width ? VERTICAL : HORIZONTAL,
        size: {
          height: panel.size.height / 1000,
          width: panel.size.width / 1000,
        },
      };

      const flippedPanel = {
        ...originalPanel,
        size: {
          height: originalPanel.size.width,
          width: originalPanel.size.height,
        },
        orientation:
          originalPanel.orientation === VERTICAL ? HORIZONTAL : VERTICAL,
      };

      defaultPanels.push(originalPanel, flippedPanel);
    });

    return defaultPanels;
  } catch (error) {
    console.error("Failed to fetch default panels:", error);
  }
};

export const getShiftedCenterPoint = function (points, inPlaneVector, normal) {
  const stepSize = 0.2;

  const vectorPoints = points.map((point) => point.position);

  const leftMostPoint = this.getLeftMostPoint(vectorPoints);
  const bottomMostPoint = this.getBottomMostPoint(vectorPoints);
  const centerPoint = this.getCenterPoint(points);

  const projectedTopLeftPoint = leftMostPoint
    .clone()
    .add(
      inPlaneVector
        .clone()
        .multiplyScalar(
          bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVector)
        )
    );

  const pos = centerPoint
    .clone()
    // move the panel vertically
    .add(inPlaneVector.clone().multiplyScalar(stepSize * this.callCount))
    // move the panel horizontally from center Point to topLeft point
    .add(
      projectedTopLeftPoint
        .clone()
        .sub(centerPoint)
        .normalize()
        .multiplyScalar(stepSize * this.callCount)
    )
    // ensure the panel is centered along the horizontal direction
    .sub(
      projectedTopLeftPoint
        .clone()
        .sub(centerPoint)
        .normalize()
        .multiplyScalar(stepSize)
    )
    // ensure the panel is sits on the plane, not just parallel to it
    .sub(normal.clone().multiplyScalar(0));

  this.callCount = this.callCount + 1;

  return pos;
};

export const getShiftedPoint = function (
  points,
  inPlaneVector,
  normal,
  index,
  i
) {
  const stepSize = 0.1;

  const vectorPoints = points.map((point) => point.position);

  const point = vectorPoints[index];
  const centerPoint = this.getCenterPoint(points);
  // const projectedTopLeftPoint = leftMostPoint
  //   .clone()
  //   .add(
  //     inPlaneVector
  //       .clone()
  //       .multiplyScalar(
  //         bottomMostPoint.clone().sub(leftMostPoint).dot(inPlaneVector)
  //       )
  //   )
  const pos = point
    .clone()
    // move the panel vertically
    // .add(inPlaneVector.clone().multiplyScalar(stepSize * this.callCount))
    // move the panel horizontally from center Point to topLeft point
    .add(
      centerPoint
        .clone()
        .sub(point)
        .normalize()
        .multiplyScalar(0.8 + stepSize * this.callCount + i)
    )
    // ensure the panel is centered along the horizontal direction
    .sub(centerPoint.clone().sub(point).normalize().multiplyScalar(stepSize))
    // ensure the panel is sits on the plane, not just parallel to it
    .sub(normal.clone().multiplyScalar(0));

  return pos;
};

export const getRightMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let rightMostPoint = screenPoints[0];
  let rightMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (rightMostPoint.x > screenPoints[i].x) {
      rightMostPoint = screenPoints[i];
      rightMostPointIndex = i;
    }
  }
  return points[rightMostPointIndex];
};

export const getLeftMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let leftMostPoint = screenPoints[0];
  let leftMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (leftMostPoint.x < screenPoints[i].x) {
      leftMostPoint = screenPoints[i];
      leftMostPointIndex = i;
    }
  }
  return points[leftMostPointIndex];
};

export const getTopMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let topMostPoint = screenPoints[0];
  let topMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (topMostPoint.y > screenPoints[i].y) {
      topMostPoint = screenPoints[i];
      topMostPointIndex = i;
    }
  }
  return points[topMostPointIndex];
};

export const getBottomMostPoint = function (points, simulatedCamera) {
  const screenPoints = points.map((point) =>
    this.toScreenPosition(point.clone(), simulatedCamera)
  );
  let bottomMostPoint = screenPoints[0];
  let bottomMostPointIndex = 0;
  for (let i = 0; i < screenPoints.length; i++) {
    if (bottomMostPoint.y < screenPoints[i].y) {
      bottomMostPoint = screenPoints[i];
      bottomMostPointIndex = i;
    }
  }
  return points[bottomMostPointIndex];
};

export const isPointWithinArea = function (
  point,
  areaVertices,
  simulatedCamera
) {
  const areaCoordinates = [];

  for (let i = 0; i < areaVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      areaVertices[i].clone(),
      simulatedCamera
    );
    areaCoordinates.push([screenPosition.x, screenPosition.y]);
  }
  const polygon = turf.polygon([[...areaCoordinates, areaCoordinates[0]]]);

  const screenPosition = this.toScreenPosition(point.clone(), simulatedCamera);
  const turfPoint = turf.point([screenPosition.x, screenPosition.y]);

  return turf.booleanPointInPolygon(turfPoint, polygon);
};

export const verticesToScreenCoordinates = function (
  vertices,
  simulatedCamera
) {
  const screenCoordinates = [];
  for (let i = 0; i < vertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      vertices[i].clone(),
      simulatedCamera
    );
    screenCoordinates.push([screenPosition.x, screenPosition.y]);
  }
  return screenCoordinates;
};

export const isRectangleIntersectingArea = function (
  panelVertices,
  panelEdges,
  areaVertices,
  areaEdges,
  simulatedCamera
) {
  // check if panel lies inside restricted area
  const areaCoordinates = this.verticesToScreenCoordinates(
    areaVertices,
    simulatedCamera
  );

  const polygon = turf.polygon([[...areaCoordinates, areaCoordinates[0]]]);

  for (let i = 0; i < panelVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      panelVertices[i].clone(),
      simulatedCamera
    );
    const point = turf.point([screenPosition.x, screenPosition.y]);

    if (turf.booleanPointInPolygon(point, polygon)) {
      return true;
    }
  }

  // check if restricted area lies inside panel
  const panelCoordinates = this.verticesToScreenCoordinates(
    panelVertices,
    simulatedCamera
  );
  const panelPolygon = turf.polygon([
    [...panelCoordinates, panelCoordinates[0]],
  ]);

  for (let i = 0; i < areaVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      areaVertices[i].clone(),
      simulatedCamera
    );
    const point = turf.point([screenPosition.x, screenPosition.y]);

    if (turf.booleanPointInPolygon(point, panelPolygon)) {
      return true;
    }
  }

  // check if restricted area intersect panel
  for (let i = 0; i < panelEdges.length; i++) {
    const panelEdgeStart = this.toScreenPosition(
      panelEdges[i][0].clone(),
      simulatedCamera
    );
    const panelEdgeEnd = this.toScreenPosition(
      panelEdges[i][1].clone(),
      simulatedCamera
    );

    const panelLine = turf.lineString([
      [panelEdgeStart.x, panelEdgeStart.y],
      [panelEdgeEnd.x, panelEdgeEnd.y],
    ]);

    for (let j = 0; j < areaEdges.length; j++) {
      const areaEdgeStart = this.toScreenPosition(
        areaEdges[j][0].clone(),
        simulatedCamera
      );
      const areaEdgeEnd = this.toScreenPosition(
        areaEdges[j][1].clone(),
        simulatedCamera
      );

      const areaLine = turf.lineString([
        [areaEdgeStart.x, areaEdgeStart.y],
        [areaEdgeEnd.x, areaEdgeEnd.y],
      ]);

      if (turf.lineIntersect(panelLine, areaLine).features.length > 0) {
        return true;
      }
    }
  }

  // Manual Check if the restricted area is inside the panel because turf sometimes fails with very small areas
  const centroid = turf.centroid(polygon);
  const areaCenter = centroid.geometry.coordinates;

  const panelMinX = Math.min(...panelCoordinates.map((pc) => pc[0]));
  const panelMaxX = Math.max(...panelCoordinates.map((pc) => pc[0]));
  const panelMinY = Math.min(...panelCoordinates.map((pc) => pc[1]));
  const panelMaxY = Math.max(...panelCoordinates.map((pc) => pc[1]));

  if (
    areaCenter[0] > panelMinX &&
    areaCenter[0] < panelMaxX &&
    areaCenter[1] > panelMinY &&
    areaCenter[1] < panelMaxY
  ) {
    return true;
  }

  return false;
};

export const isRectangleWithinPoints = function (
  panelVertices,
  panelEdges,
  areaVertices,
  areaEdges,
  simulatedCamera
) {
  const areaCoordinates = this.verticesToScreenCoordinates(
    areaVertices,
    simulatedCamera
  );

  const polygon = turf.polygon([[...areaCoordinates, areaCoordinates[0]]]);

  for (let i = 0; i < panelVertices.length; i++) {
    const screenPosition = this.toScreenPosition(
      panelVertices[i].clone(),
      simulatedCamera
    );
    const point = turf.point([screenPosition.x, screenPosition.y]);

    if (!turf.booleanPointInPolygon(point, polygon)) {
      return false;
    }
  }

  // check if edges are within area
  for (let i = 0; i < panelEdges.length; i++) {
    const panelEdgeStart = this.toScreenPosition(
      panelEdges[i][0].clone(),
      simulatedCamera
    );
    const panelEdgeEnd = this.toScreenPosition(
      panelEdges[i][1].clone(),
      simulatedCamera
    );

    for (let j = 0; j < areaEdges.length; j++) {
      const areaEdgeStart = this.toScreenPosition(
        areaEdges[j][0].clone(),
        simulatedCamera
      );
      const areaEdgeEnd = this.toScreenPosition(
        areaEdges[j][1].clone(),
        simulatedCamera
      );

      const line1 = turf.lineString([
        [panelEdgeStart.x, panelEdgeStart.y],
        [panelEdgeEnd.x, panelEdgeEnd.y],
      ]);
      const line2 = turf.lineString([
        [areaEdgeStart.x, areaEdgeStart.y],
        [areaEdgeEnd.x, areaEdgeEnd.y],
      ]);

      if (turf.lineIntersect(line1, line2).features.length > 0) {
        return false;
      }
    }
  }
  return true;
};

export const toScreenPosition = function (vector, camera) {
  vector.project(camera);

  vector.x = Math.round(
    ((vector.x + 1) * this.renderer.getContext().canvas.width) / 2
  );
  vector.y = Math.round(
    ((-vector.y + 1) * this.renderer.getContext().canvas.height) / 2
  );
  vector.z = 0;
  return {
    x: vector.x,
    y: vector.y,
  };
};

export const isInside = function (point, vs, indices) {
  // Triangulate the polygon
  const triangles = [];
  for (let i = 0; i < indices.length; i++) {
    if (triangles.length > 0 && triangles[triangles.length - 1].length < 3) {
      triangles[triangles.length - 1].push(vs[indices[i]]);
    } else {
      triangles.push([vs[indices[i]]]);
    }
  }

  // Check if the point is inside any of the triangles
  for (const triangle of triangles) {
    const [v1, v2, v3] = triangle;
    const a =
      (1 / 2) *
      (-v2.y * v3.x +
        v1.y * (-v2.x + v3.x) +
        v1.x * (v2.y - v3.y) +
        v2.x * v3.y);
    const sign = a < 0 ? -1 : 1;
    const s =
      (v1.y * v3.x -
        v1.x * v3.y +
        (v3.y - v1.y) * point.x +
        (v1.x - v3.x) * point.y) *
      sign;
    const t =
      (v1.x * v2.y -
        v1.y * v2.x +
        (v1.y - v2.y) * point.x +
        (v2.x - v1.x) * point.y) *
      sign;
    if (s > 0 && t > 0 && s + t < 2 * a * sign) {
      return true;
    }
  }

  return false;
};

export const undoAddPanel = async function (panel, area) {
  // add action to redo stack
  this.redoStack.push({ action: "ADD_PANEL", panel: panel, area: area });

  const panelObject = this.scene.getObjectById(panel.plane.id);
  area.panels = area.panels.filter((p) => p.plane.id !== panel.plane.id);
  panelObject.visible = false;

  await this.deletePanelObject(panel.id);
};

export const redoAddPanel = async function (panel, area) {
  // add action to undo stack
  this.undoStack.push({ action: "ADD_PANEL", panel: panel, area: area });

  const panelObject = this.scene.getObjectById(panel.plane.id);

  if (!this.anonymousUser) {
    try {
      const { data } = await this.createPanelObject(
        area.id,
        {
          x: panelObject.position.x,
          y: panelObject.position.y,
          z: panelObject.position.z,
        },
        panel.panelId,
        panel.orientation,
        panel.plane.renderOrder
      );
      panel.id = data;
    } catch (e) {}
  }

  area.panels.push(panel);
  panelObject.visible = true;
};

export const undoBulkAddPanels = async function (panels, area) {
  // add action to redo stack
  this.redoStack.push({
    action: "BULK_ADD_PANELS",
    panels: panels,
    area: area,
  });

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    area.panels = area.panels.filter((p) => p.plane.id !== panel.plane.id);
    panelObject.visible = false;

    panelPromises.push(this.deletePanelObject(panel.id));
  }

  Promise.all(panelPromises);
};

export const redoBulkAddPanels = async function (panels, area) {
  // add action to undo stack
  this.undoStack.push({
    action: "BULK_ADD_PANELS",
    panels: panels,
    area: area,
  });

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    area.panels.push(panel);
    panelObject.visible = true;

    panelPromises.push(
      this.createPanelObject(
        area.id,
        {
          x: panelObject.position.x,
          y: panelObject.position.y,
          z: panelObject.position.z,
        },
        panel.panelId,
        panel.orientation,
        panel.plane.renderOrder
      )
    );
  }
  Promise.all(panelPromises);
};

export const undoMovePanels = async function (panels, area) {
  for (let panel of panels) {
    const currentPositon = {
      x: panel.plane.position.x,
      y: panel.plane.position.y,
      z: panel.plane.position.z,
    };
    panel.plane.currentPosition = currentPositon;
  }

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.position.x = panelObject.prevPosition.x;
    panelObject.position.y = panelObject.prevPosition.y;
    panelObject.position.z = panelObject.prevPosition.z;

    panelObject.prevPosition.x = panelObject.currentPosition.x;
    panelObject.prevPosition.y = panelObject.currentPosition.y;
    panelObject.prevPosition.z = panelObject.currentPosition.z;

    panelObject.visible = true;

    await this.updatePanelObject(
      panel.id,
      area.id,
      {
        x: panelObject.position.x,
        y: panelObject.position.y,
        z: panelObject.position.z,
      },
      panel.panelId,
      panel.orientation,
      panelObject.renderOrder
    );

    if (area.panels.find((p) => p.plane.id === panel.plane.id)) {
      let index = area.panels.findIndex((p) => p.plane.id === panel.plane.id);
      area.panels.splice(index, 1, panel);
    } else {
      area.panels.push(panel);
    }
  }

  for (let panel of area.panels) {
    panel.selected = false;
  }
  // add action to redo stack
  this.redoStack.push({
    action: "MOVE_PANELS",
    panels: panels,
    area: area,
  });
};

export const redoMovePanels = async function (panels, area) {
  for (let panel of panels) {
    const currentPositon = {
      x: panel.plane.position.x,
      y: panel.plane.position.y,
      z: panel.plane.position.z,
    };
    panel.plane.currentPosition = currentPositon;
  }
  const oldPanels = [].concat(panels);

  const panelPromises = [];

  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.position.x = panelObject.prevPosition.x;
    panelObject.position.y = panelObject.prevPosition.y;
    panelObject.position.z = panelObject.prevPosition.z;

    panelObject.prevPosition.x = panelObject.currentPosition.x;
    panelObject.prevPosition.y = panelObject.currentPosition.y;
    panelObject.prevPosition.z = panelObject.currentPosition.z;

    if (panel.deleted) {
      panelObject.visible = false;

      panelPromises.push(this.deletePanelObject(panel.id));
    } else {
      panelPromises.push(
        this.updatePanelObject(
          panel.id,
          area.id,
          {
            x: panelObject.position.x,
            y: panelObject.position.y,
            z: panelObject.position.z,
          },
          panel.panelId,
          panel.orientation,
          panelObject.renderOrder
        )
      );
    }
  }

  Promise.all(panelPromises);

  area.panels = area.panels.filter((panel) => !panel.deleted);

  for (let panel of area.panels) {
    panel.selected = false;
  }

  // add action to redo stack
  this.undoStack.push({
    action: "MOVE_PANELS",
    panels: oldPanels,
    area: area,
  });
};

export const undoDeletePanels = async function (panels, area) {
  // add action to redo stack
  this.redoStack.push({
    action: "DELETE_PANELS",
    panels: panels,
    area: area,
  });

  // execute undo action
  for (let panel of panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);

    if (!this.anonymousUser) {
      try {
        const { data } = await this.createPanelObject(
          area.id,
          {
            x: panelObject.position.x,
            y: panelObject.position.y,
            z: panelObject.position.z,
          },
          panel.panelId,
          panel.orientation,
          panel.plane.renderOrder
        );
        panel.id = data;
      } catch (e) {}
    }

    panelObject.visible = true;
  }
  area.panels.push(...panels);
  area.panels.forEach((panel) => (panel.selected = false));
};

export const redoDeletePanels = function (panels, area) {
  // add action to redo stack
  this.undoStack.push({
    action: "DELETE_PANELS",
    panels: panels,
    area: area,
  });

  // execute redo action
  this.hidePanels(panels, area);
  area.panels.forEach((panel) => (panel.selected = false));
};

export const displayPanel = function (panel) {
  let areaObject;
  this.areas.forEach((area) => {
    if (area.id == panel.areaId) {
      areaObject = area;
    }
  });
  if (!areaObject) return;
  const panelPlanes = this.verticalPanelTypes
    .concat(this.horizontalPanelTypes)
    .concat(this.customPanels);
  let panelId;
  const firstVerticalPanel = panelPlanes.find(
    (panelPlane) => panelPlane.orientation === VERTICAL
  );
  this.defaultVerticalTexture = firstVerticalPanel.texture;
  this.defaultHorizontalTexture = panelPlanes.find(
    (panelPlane) =>
      panelPlane.id === firstVerticalPanel.id &&
      panelPlane.orientation === HORIZONTAL
  ).texture;
  panelPlanes.forEach((panelPlane) => {
    // backwards compatibility for old panels without panelId
    if (
      !panel.panelId &&
      panel.size.width == panelPlane.size.width &&
      panel.size.height == panelPlane.size.height
    ) {
      panel.texture = panelPlane.texture;
      panel.orientation = panelPlane.orientation;
      panel.panelId = panelPlane.id;
    } else if (
      panel.panelId &&
      panel.panelId == panelPlane.id &&
      panel.orientation == panelPlane.orientation
    ) {
      panelId = panelPlane.id;
      panel.texture = panelPlane.texture;
      panel.size = {};
      panel.size.height = panelPlane.size.height;
      panel.size.width = panelPlane.size.width;
    }
  });
  if (!panelPlanes.includes(panelId)) {
    panel.texture = this.isPanelVertical(panel)
      ? this.defaultVerticalTexture
      : this.defaultHorizontalTexture;
    panel.orientation == this.getPanelOrientation(panel);
  }
  const points = areaObject?.points.map((point) => point.position);
  const point1 = new THREE.Vector3(
    areaObject?.points[0]?.position?.x,
    areaObject?.points[0]?.position?.y,
    areaObject?.points[0]?.position?.z
  );
  const point2 = new THREE.Vector3(
    areaObject?.points[1]?.position?.x,
    areaObject?.points[1]?.position?.y,
    areaObject?.points[1]?.position?.z
  );
  const point3 = new THREE.Vector3(
    areaObject?.points[2]?.position?.x,
    areaObject?.points[2]?.position?.y,
    areaObject?.points[2]?.position?.z
  );
  // Find the longest edge
  let longestEdge = null;
  let longestEdgeLength = 0;
  for (let i = 0; i < points?.length; i++) {
    const edge = new THREE.Vector3().subVectors(
      points[i],
      points[(i + 1) % points.length]
    );
    const edgeLength = edge.length();
    if (edgeLength > longestEdgeLength) {
      longestEdgeLength = edgeLength;
      longestEdge = edge;
    }
  }
  const normal = new THREE.Vector3();
  normal
    .crossVectors(
      point2.clone().sub(point1.clone()),
      point3.clone().sub(point1)
    )
    .normalize();
  const inPlaneVector = new THREE.Vector3()
    .crossVectors(normal, longestEdge)
    .normalize();

  if (panel.size.width > 100 || panel.size.height > 100) {
    panel.size.width = panel.size.width / 1000;
    panel.size.height = panel.size.height / 1000;
  }
  const planeGeometry = new THREE.PlaneGeometry(
    panel.size.width,
    panel.size.height
  );
  let planeMaterial;
  let needsUpdate = false;
  if (this.texturesLoading || isPanelSmall(panel.size)) {
    planeMaterial = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: areaObject?.transparencyLevel / 100,
    });
    needsUpdate = true;
  } else {
    planeMaterial = new THREE.MeshBasicMaterial({
      map: panel.texture,
      transparent: true,
      opacity: areaObject?.transparencyLevel / 100,
    });
  }
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);
  plane.material.depthTest = false;
  plane.renderOrder =
    panel.renderOrder || RENDERING_ORDER.PANEL + this.panelCounter;
  const panelPosition = new THREE.Vector3(
    panel?.position?.x,
    panel?.position?.y,
    panel?.position?.z
  );

  const toCamera = new THREE.Vector3()
    .subVectors(this.camera.position, panelPosition)
    .normalize();

  if (normal.dot(toCamera) < 0) {
    normal.negate();
  }

  const offsetDistance = 0.05;
  const offset = normal.clone().multiplyScalar(offsetDistance);
  const offsetPosition = panelPosition.clone().add(offset);

  plane.position.copy(offsetPosition);
  plane.up.copy(inPlaneVector);
  plane.lookAt(offsetPosition.clone().add(normal));
  const geometry = plane.geometry.clone();
  plane.updateMatrix();
  plane.updateWorldMatrix();
  geometry.applyMatrix4(plane.matrixWorld);

  const iconMaterial = new THREE.MeshBasicMaterial({
    map: this.closeTexture,
    transparent: true,
  });

  const trashDimension =
    this.trashSize.width * Math.min(panel.size.width, panel.size.height);

  const iconGeomtery = new THREE.PlaneGeometry(trashDimension, trashDimension);
  const icon = new THREE.Mesh(iconGeomtery, iconMaterial);
  icon.position.set(
    panel.size.width / 2 - trashDimension / 2,
    panel.size.height / 2 - trashDimension / 2,
    0
  );
  icon.material.depthTest = false;
  icon.renderOrder =
    panel.renderOrder + 1 || RENDERING_ORDER.PANEL + this.panelCounter + 1;
  icon.visible = true;
  plane.add(icon);
  this.scene.add(plane);
  const planePanel = {
    id: panel.id,
    plane: plane,
    icon: icon,
    size: {
      width: panel.size.width,
      height: panel.size.height,
    },
    panelId: panel.panelId,
    orientation: panel.orientation,
    placedBefore: true,
  };
  planePanel.icon.visible = false;
  areaObject?.panels.push(planePanel);
  if (needsUpdate) this.panelsToUpdate.push(planePanel);
  this.hideAreaPoints(areaObject);
  this.disablePointDragMode();
  this.isEditButtonDisplayed = areaObject?.panels?.length > 0;
  this.panelCounter++;
};

export const loadCustomPanelTextures = async function () {
  const textureLoader = new THREE.TextureLoader();
  const textureUrls = [
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
    { id: 90, url: "solar_panel_texture_half_cell_90_horizontal.png" },
    { id: 90, url: "solar_panel_texture_half_cell_90_horizontal.png" },
  ];

  const texturePromises = textureUrls.map(
    (textureObj, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + textureObj.url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        this.customPanels
          .filter((panel) => panel.textureId == textureObj.id)
          .forEach((panel) => {
            if (index % 2 === 0) {
              if (panel.orientation === VERTICAL) {
                panel.texture = textureMap;
              }
            } else {
              if (panel.orientation === HORIZONTAL) {
                panel.texture = textureMap;
              }
            }
          });
      })
  );

  Promise.all(texturePromises)
    .then(() => {
      this.texturesLoading = false;
    })
    .catch((error) => {
      console.error("Error loading textures:", error);
    });
};

export const loadPanelTextures = async function () {
  const textureLoader = new THREE.TextureLoader();
  const textureUrls = [];
  for (let i = 0; i < this.horizontalPanelTypes.length; i++) {
    const id = this.horizontalPanelTypes[i].textureId;
    let horizontalTexture = `solar_panel_texture_half_cell_${id}_horizontal.png`;
    textureUrls.push(horizontalTexture, horizontalTexture);
  }

  const customTextureUrls = [
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 60, url: "solar_panel_texture_half_cell_60_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
    { id: 80, url: "solar_panel_texture_half_cell_80_horizontal.png" },
    { id: 90, url: "solar_panel_texture_half_cell_90_horizontal.png" },
    { id: 90, url: "solar_panel_texture_half_cell_90_horizontal.png" },
  ];

  const defaultPanelsTexturePromises = textureUrls.map(
    (url, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        if (index % 2 === 0) {
          this.verticalPanelTypes[index / 2].texture = textureMap;
        } else {
          this.horizontalPanelTypes[Math.floor(index / 2)].texture = textureMap;
        }
      })
  );

  const customPanelsTexturePromises = customTextureUrls.map(
    (textureObj, index) =>
      new Promise((resolve) => {
        const textureMap = textureLoader.load(
          this.texturePath + textureObj.url,
          (texture) => {
            if (index % 2 === 0) {
              texture.center = new THREE.Vector2(0.5, 0.5);
              texture.rotation = Math.PI / 2;
              texture.flipY = false;
            }
            resolve(texture);
          }
        );
        this.customPanels
          .filter((panel) => panel.textureId == textureObj.id)
          .forEach((panel) => {
            if (index % 2 === 0) {
              if (panel.orientation === VERTICAL) {
                panel.texture = textureMap;
              }
            } else {
              if (panel.orientation === HORIZONTAL) {
                panel.texture = textureMap;
              }
            }
          });
      })
  );

  const texturePromises = [].concat(
    defaultPanelsTexturePromises,
    customPanelsTexturePromises
  );

  Promise.all(texturePromises)
    .then(() => {
      this.texturesLoading = false;
      const panelPlanes = this.verticalPanelTypes
        .concat(this.horizontalPanelTypes)
        .concat(this.customPanels);
      this.panelsToUpdate.forEach((panel) => {
        panelPlanes.forEach((panelPlane) => {
          // backwards compatibility for old panels without panelId
          if (
            !panel.panelId &&
            panel.size.width == panelPlane.size.width &&
            panel.size.height == panelPlane.size.height
          ) {
            panel.texture = panelPlane.texture;
            panel.orientation = panelPlane.orientation;
            panel.panelId = panelPlane.id;
          } else if (
            panel.panelId &&
            panel.panelId == panelPlane.id &&
            panel.orientation == panelPlane.orientation
          ) {
            panel.texture = panelPlane.texture;
            panel.size = {};
            panel.size.height = panelPlane.size.height;
            panel.size.width = panelPlane.size.width;
          }
        });
        if (!panelPlanes.includes(panel.panelId)) {
          panel.texture = this.isPanelVertical(panel)
            ? this.defaultVerticalTexture
            : this.defaultHorizontalTexture;
          panel.orientation == this.getPanelOrientation(panel);
        }
        if (panel.textureId) {
          const material = panel.plane.material;
          material.color = undefined;
          material.map = panel.texture;
          material.needsUpdate = true;
        }
      });
    })
    .catch((error) => {
      console.error("Error loading textures:", error);
    });
  this.closeTexture = new THREE.TextureLoader().load("/assets/model/trash.png");
};

export const createPanelInstance = function (
  panelWidth,
  panelHeight,
  panelTexture,
  panelsCount
) {
  const geometry = new THREE.PlaneGeometry(panelWidth, panelHeight);
  let material = null;
  if (!panelTexture) {
    material = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: 0.5,
    });
  } else {
    material = new THREE.MeshBasicMaterial({
      map: panelTexture,
      transparent: true,
      opacity: 0.5,
    });
  }

  const instancedMesh = new InstancedMesh2(
    this.renderer,
    panelsCount,
    geometry,
    material
  );

  for (let i = 0; i < panelsCount; i++) {
    instancedMesh.setColorAt(0, new THREE.Color(1.0, 1.0, 1.0));
  }

  return instancedMesh;
};

export const replacePanelInstance = function (
  instancedMeshToReplace,
  newInstancedMesh,
  skipIndex = -1
) {
  const transformationMatrix = new THREE.Matrix4();
  let newIndex = 0;
  for (let i = 0; i < instancedMeshToReplace.instancesCount; i++) {
    if (i === skipIndex) continue;
    instancedMeshToReplace.getMatrixAt(i, transformationMatrix);
    newInstancedMesh.setMatrixAt(newIndex, transformationMatrix);
    newIndex++;
  }

  newInstancedMesh.instanceMatrix.needsUpdate = true;

  this.removeObjectFromScene(instancedMeshToReplace);
};

export const createTempPanel = function (panel) {
  const geometry = new THREE.PlaneGeometry(panel.size.width, panel.size.height);
  const material = new THREE.MeshBasicMaterial({
    color: BLACK,
  });
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export const createPanelMesh = function (area, panel) {
  const geometry = new THREE.PlaneGeometry(panel.size.width, panel.size.height);

  let material;
  if (isPanelSmall(panel.size)) {
    material = new THREE.MeshBasicMaterial({
      color: BLACK,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  } else {
    material = new THREE.MeshBasicMaterial({
      map: panel.texture,
      transparent: true,
      opacity: area.transparencyLevel / 100,
    });
  }
  const mesh = new THREE.Mesh(geometry, material);
  return mesh;
};

export const getPanelOrientation = function (panel) {
  if (panel.size.width > panel.size.height) return HORIZONTAL;
  return VERTICAL;
};

export const isPanelVertical = function (panel) {
  if (this.getPanelOrientation(panel) === VERTICAL) return true;
  else return false;
};

export const chunkArray = function (array, chunkSize) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
};

export const getPlacementTitle = function (index) {
  switch (index) {
    case 0:
      return "DEFAULT";
    case 1:
      return "VERTICAL_OFFSET";
    case 2:
      return "HORIZONTAL_OFFSET";
    case 3:
      return "DOUBLE_OFFSET";
    case 4:
      return "CENTER";
    default:
      return "";
  }
};

export const getPlacementIndex = function (title) {
  switch (title) {
    case "DEFAULT":
      return 0;
    case "VERTICAL_OFFSET":
      return 1;
    case "HORIZONTAL_OFFSET":
      return 2;
    case "DOUBLE_OFFSET":
      return 3;
    case "CENTER":
      return 4;
    default:
      return -1;
  }
};

const createGridElement = function () {
  const element = document.createElement("img");
  element.style =
    "cursor:grab; pointer-events: all; opacity: 0.7; width: 34px;";
  element.style.transition = "opacity 0.3s ease";
  return element;
};

export const createGridLabel = function (elememt, position) {
  const label = new CSS2DObject(elememt);
  label.position.set(position.x, position.y, position.z);
  label.layers.set(0);
  label.renderOrder = RENDERING_ORDER.MOVE_GRID;
  return label;
};

export const getPanelName = function (area) {
  const panel = this.getPanelById(area.panelId, area.orientation);
  return panel?.name || "";
};

export const calculateVisiblePanelsArea = function (area) {
  const { instancedMesh, testPanel } = area;
  let totalVisibleArea = 0;

  // Assuming the panel is a plane, get its width and height from the geometry
  const panelWidth = testPanel.geometry.parameters.width;
  const panelHeight = testPanel.geometry.parameters.height;
  const panelArea = panelWidth * panelHeight;

  if (instancedMesh) {
    // Iterate over each instance to check visibility and calculate total area
    for (let i = 0; i < instancedMesh.instancesCount; i++) {
      if (instancedMesh.getVisibilityAt(i)) {
        totalVisibleArea += panelArea;
      }
    }
  }

  return parseFloat(totalVisibleArea.toFixed(2)); // Ensure it returns a number
};

export const checkPanelsInSolarArea = function (area) {
  const {
    testPanel,
    verticalVector,
    normal,
    ground,
    simulatedCamera,
    innerPlane,
    instancedMesh,
    restrictedAreas,
    verticalPanelInstancedMesh,
    verticalTestPanel,
    horizontalPanelInstancedMesh,
    horizontalTestPanel,
  } = area;

  const {
    transformationPosition: tempPosition,
    transformationQuaternion: tempQuaternion,
    transformationScale: tempScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  const offset = normal.clone().multiplyScalar(area.offset / 100);

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);

    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    if (
      !this.panelInsideArea(
        tempPositionWithoutOffset,
        tempQuaternion,
        testPanel,
        verticalVector,
        normal,
        simulatedCamera,
        innerPlane
      )
    ) {
      instancedMesh.setVisibilityAt(i, false);
    } else {
      instancedMesh.setVisibilityAt(i, true);

      const { panelVertices, panelEdges } = this.createPanelAt({
        panel: testPanel,
        pos: tempPositionWithoutOffset,
        rotation: tempQuaternion,
        ground,
        normal,
      });

      let isVisible = this.checkPanelAgainstRestrictedAreas(
        panelEdges,
        panelVertices,
        restrictedAreas,
        simulatedCamera
      );

      if (isVisible && verticalPanelInstancedMesh) {
        isVisible = this.checkPanelAgainstIndividualPanels(
          panelVertices,
          panelEdges,
          verticalPanelInstancedMesh,
          verticalTestPanel,
          offset,
          ground,
          normal,
          simulatedCamera
        );
      }

      if (isVisible && horizontalPanelInstancedMesh) {
        isVisible = this.checkPanelAgainstIndividualPanels(
          panelVertices,
          panelEdges,
          horizontalPanelInstancedMesh,
          horizontalTestPanel,
          offset,
          ground,
          normal,
          simulatedCamera
        );
      }

      instancedMesh.setVisibilityAt(i, isVisible);
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const checkPanelAgainstRestrictedAreas = function (
  panelEdges,
  panelVertices,
  restrictedAreas,
  simulatedCamera
) {
  for (const restrictedArea of restrictedAreas) {
    const restrictedAreaPoints = restrictedArea.points.map(
      (point) =>
        new THREE.Vector3(point.position.x, point.position.y, point.position.z)
    );

    // for (let point of restrictedAreaPoints) {
    //   this.addPointToScene(point, 0x0000ff);
    // }

    // for (let point of panelVertices) {
    //   this.addPointToScene(point, 0x00ff00);
    // }

    if (
      this.isRectangleIntersectingArea(
        panelVertices,
        panelEdges,
        restrictedAreaPoints,
        restrictedArea.edges,
        simulatedCamera
      )
    ) {
      return false;
    }
  }

  return true;
};

export const checkPanelAgainstIndividualPanels = function (
  panelVertices,
  panelEdges,
  instancedMesh,
  testPanel,
  offset,
  ground,
  normal,
  simulatedCamera,
  skipIndex = -1
) {
  if (!instancedMesh) return true;
  const panelsCount = instancedMesh.instancesCount;

  const {
    transformationPosition: tempPosition,
    transformationQuaternion: tempQuaternion,
    transformationScale: tempScale,
    transformationMatrix: transformationMatrix,
  } = this.createTransformationComponents();

  for (let j = 0; j < panelsCount; j++) {
    if (j === skipIndex) continue;

    instancedMesh.getMatrixAt(j, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    const {
      panelVertices: individualPanelVertices,
      panelEdges: individualPanelEdges,
    } = this.createPanelAt({
      panel: testPanel,
      pos: tempPositionWithoutOffset,
      rotation: tempQuaternion,
      ground,
      normal,
    });

    if (
      this.isRectangleIntersectingArea(
        panelVertices,
        panelEdges,
        individualPanelVertices,
        individualPanelEdges,
        simulatedCamera
      )
    ) {
      return false;
    }
  }

  return true;
};

export const enableIndividualPlacementMode = function (orientation) {
  this.toggleActive(3);
  this.individualPanelOrientation = orientation;
};

export const disableIndividualPlacementMode = function () {
  this.modes.find((mode) => mode.value === 3).disableFunction();
};

export const updateCursorForIndividualPlacement = function (event) {
  if (event.target.tagName !== "CANVAS") return this.restoreDefaultCursor();
  if (this.draggedIndividualPanel) return this.changeCursorToMove();

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.selectedArea.innerPlane);
  if (intersects.length < 1) return this.changeCursorToBlocked();

  const isVertical = this.individualPanelOrientation === "VERTICAL";

  if (isVertical && !this.selectedArea.verticalTestPanel) {
    const chosenPanel = this.getPanelById(
      this.selectedArea.panelId,
      isVertical
    );
    const individualTestPanel = this.createTempPanel(chosenPanel);
    this.selectedArea.verticalTestPanel = individualTestPanel;

    const trashIcon = this.createTrashIcon(chosenPanel, false);
    this.selectedArea.verticalTrashIcon = trashIcon;
    this.scene.add(trashIcon);
  } else if (!isVertical && !this.selectedArea.horizontalTestPanel) {
    const chosenPanel = this.getPanelById(
      this.selectedArea.panelId,
      isVertical
    );
    const individualTestPanel = this.createTempPanel(chosenPanel);
    this.selectedArea.horizontalTestPanel = individualTestPanel;

    const trashIcon = this.createTrashIcon(chosenPanel, false);
    this.selectedArea.horizontalTrashIcon = trashIcon;
    this.scene.add(trashIcon);
  }

  if (this.selectedArea.verticalTrashIcon) {
    const trashIntersects = this.raycaster.intersectObject(
      this.selectedArea.verticalTrashIcon,
      true
    );
    if (
      trashIntersects.length > 0 &&
      this.selectedArea.verticalTrashIcon.visible
    )
      return this.changeCursorToPointer();

    if (this.selectedArea.verticalPanelInstancedMesh) {
      const panelIntersects = this.raycaster.intersectObject(
        this.selectedArea.verticalPanelInstancedMesh
      );
      if (panelIntersects.length > 0) return this.changeCursorToMove();
    }
  }

  if (this.selectedArea.horizontalTrashIcon) {
    const trashIntersects = this.raycaster.intersectObject(
      this.selectedArea.horizontalTrashIcon,
      true
    );
    if (
      trashIntersects.length > 0 &&
      this.selectedArea.horizontalTrashIcon.visible
    )
      return this.changeCursorToPointer();

    if (this.selectedArea.horizontalPanelInstancedMesh) {
      const panelIntersects = this.raycaster.intersectObject(
        this.selectedArea.horizontalPanelInstancedMesh
      );
      if (panelIntersects.length > 0) return this.changeCursorToMove();
    }
  }

  const isValidPosition = this.validatePanelPosition(
    intersects[0].point,
    isVertical,
    this.selectedArea
  );

  if (!isValidPosition) return this.changeCursorToBlocked();

  return this.changeCursorToCrosshair();
};

export const validatePanelPosition = function (
  position,
  isVertical,
  area,
  skipIndex = -1
) {
  const {
    ground,
    normal,
    verticalVector,
    innerPlane,
    simulatedCamera,
    restrictedAreas,
    verticalPanelInstancedMesh,
    verticalTestPanel,
    horizontalPanelInstancedMesh,
    horizontalTestPanel,
    currentRotation,
    areaCenter,
  } = area;

  const offset = normal.clone().multiplyScalar(area.offset / 100);

  const transformationMatrix = this.createTransformationMatrix({
    position,
    normal,
    verticalVector,
    areaCenter,
    rotation: currentRotation,
    selfRotate: true,
  });

  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
  } = this.createTransformationComponents();

  transformationMatrix.decompose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  let individualTestPanel = isVertical
    ? area.verticalTestPanel
    : area.horizontalTestPanel;

  const { panelVertices, panelEdges } = this.createPanelAt({
    panel: individualTestPanel,
    pos: transformationPosition,
    rotation: transformationQuaternion,
    ground,
    normal,
  });

  if (
    !this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      innerPlane.points,
      innerPlane.edges,
      simulatedCamera
    )
  ) {
    return false;
  }

  if (
    !this.checkPanelAgainstRestrictedAreas(
      panelEdges,
      panelVertices,
      restrictedAreas,
      simulatedCamera
    )
  )
    return false;

  if (
    verticalPanelInstancedMesh &&
    !this.checkPanelAgainstIndividualPanels(
      panelVertices,
      panelEdges,
      verticalPanelInstancedMesh,
      verticalTestPanel,
      offset,
      ground,
      normal,
      simulatedCamera,
      isVertical ? skipIndex : -1
    )
  )
    return false;

  if (
    horizontalPanelInstancedMesh &&
    !this.checkPanelAgainstIndividualPanels(
      panelVertices,
      panelEdges,
      horizontalPanelInstancedMesh,
      horizontalTestPanel,
      offset,
      ground,
      normal,
      simulatedCamera,
      isVertical ? -1 : skipIndex
    )
  )
    return false;

  return true;
};

export const addIndividualPanel = function (event) {
  if (this.disableClick(event)) return;

  if (this.previousSolarArea.plane.id !== this.selectedArea.plane.id) {
    this.disableIndividualPlacementMode();
    return;
  }

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.selectedArea.innerPlane);
  if (intersects.length < 1) return;

  const isVertical = this.individualPanelOrientation === "VERTICAL";

  const isValidPosition = this.validatePanelPosition(
    intersects[0].point,
    isVertical,
    this.selectedArea
  );

  if (!isValidPosition) return;

  const position = intersects[0].point;

  const chosenPanel = this.getPanelById(this.selectedArea.panelId, isVertical);
  const panelTexture = chosenPanel.texture;
  const panelWidth = chosenPanel.size.width;
  const panelHeight = chosenPanel.size.height;

  let instancedMesh, instancedMeshToReplace;
  let panelsCount = 1;

  if (isVertical && this.selectedArea.verticalPanelInstancedMesh) {
    instancedMeshToReplace = this.selectedArea.verticalPanelInstancedMesh;
    panelsCount += instancedMeshToReplace.instancesCount;
  } else if (!isVertical && this.selectedArea.horizontalPanelInstancedMesh) {
    instancedMeshToReplace = this.selectedArea.horizontalPanelInstancedMesh;
    panelsCount += instancedMeshToReplace.instancesCount;
  }

  instancedMesh = this.createPanelInstance(
    panelWidth,
    panelHeight,
    panelTexture,
    panelsCount
  );

  this.scene.add(instancedMesh);

  if (instancedMeshToReplace) {
    this.replacePanelInstance(instancedMeshToReplace, instancedMesh);
  }

  if (isVertical) {
    this.selectedArea.verticalPanelInstancedMesh = instancedMesh;
  } else {
    this.selectedArea.horizontalPanelInstancedMesh = instancedMesh;
  }

  const offset = this.selectedArea.normal
    .clone()
    .multiplyScalar(this.selectedArea.offset / 100);

  const transformationMatrix = this.createTransformationMatrix({
    position: position,
    normal: this.selectedArea.normal,
    verticalVector: this.selectedArea.verticalVector,
    areaCenter: this.selectedArea.areaCenter,
    rotation: this.selectedArea.currentRotation,
    selfRotate: true,
    offset,
  });

  instancedMesh.setMatrixAt(panelsCount - 1, transformationMatrix);
  instancedMesh.instanceMatrix.needsUpdate = true;

  this.createIndividualPanelObject(position, isVertical, this.selectedArea);

  this.checkPanelsInSolarArea(this.selectedArea);
};

export const createIndividualPanelObject = async function (
  point,
  isVertical,
  area
) {
  if (this.sample) return;

  const newObject = {
    position: point,
    orientation: isVertical ? "vertical" : "horizontal",
  };

  const individualPanels = area.individual_panels
    ? [...area.individual_panels, newObject]
    : [newObject];

  const data = {
    individual_panels: individualPanels,
  };

  try {
    const response = await API.airteam3DViewer.patchSolarAreaObject(
      data,
      area.id
    );

    area.individual_panels = individualPanels;

    return response.data;
  } catch (error) {
    console.error("Error occurred while updating solar group object:", error);
    throw error;
  }
};

export const updateIndividualPanelsObject = async function (area) {
  if (this.sample) return;

  const data = {
    individual_panels: area.individual_panels,
  };

  try {
    const response = await API.airteam3DViewer.patchSolarAreaObject(
      data,
      area.id
    );

    return response.data;
  } catch (error) {
    console.error("Error occurred while updating solar group object:", error);
    throw error;
  }
};

export const createTransformationComponents = function () {
  const transformationComponents = {
    transformationPosition: new THREE.Vector3(),
    transformationTarget: new THREE.Vector3(),
    transformationQuaternion: new THREE.Quaternion(),
    transformationScale: new THREE.Vector3(1, 1, 1),
    transformationMatrix: new THREE.Matrix4(),
  };

  return transformationComponents;
};

export const toggleIndividualPanelOrientation = function () {
  if (this.individualPanelOrientation === "VERTICAL") {
    this.individualPanelOrientation = "HORIZONTAL";
  } else {
    this.individualPanelOrientation = "VERTICAL";
  }
};

export const displayIndividualPanels = function (area) {
  if (!area.individual_panels || area.individual_panels.length === 0) return;

  const verticalPanels = area.individual_panels.filter(
    (panel) => panel.orientation === "vertical"
  );
  const horizontalPanels = area.individual_panels.filter(
    (panel) => panel.orientation === "horizontal"
  );

  const offset = area.normal.clone().multiplyScalar(area.offset / 100);

  let chosenPanel, instancedMesh;

  if (verticalPanels.length > 0) {
    chosenPanel = this.getPanelById(area.panelId, true);
    this.selectedArea.verticalTestPanel = this.createTempPanel(chosenPanel);

    instancedMesh = this.createPanelInstance(
      chosenPanel.size.width,
      chosenPanel.size.height,
      chosenPanel.texture,
      verticalPanels.length
    );

    this.scene.add(instancedMesh);

    for (let i = 0; i < verticalPanels.length; i++) {
      const position = verticalPanels[i].position;

      const transformationMatrix = this.createTransformationMatrix({
        position,
        normal: area.normal,
        verticalVector: area.verticalVector,
        areaCenter: area.areaCenter,
        rotation: area.currentRotation,
        selfRotate: false,
        offset,
      });

      instancedMesh.setMatrixAt(i, transformationMatrix);
    }

    instancedMesh.instanceMatrix.needsUpdate = true;
    this.selectedArea.verticalPanelInstancedMesh = instancedMesh;

    const trashIcon = this.createTrashIcon(chosenPanel, false);
    this.selectedArea.verticalTrashIcon = trashIcon;
    this.scene.add(trashIcon);
  }

  if (horizontalPanels.length > 0) {
    chosenPanel = this.getPanelById(area.panelId, false);
    this.selectedArea.horizontalTestPanel = this.createTempPanel(chosenPanel);

    instancedMesh = this.createPanelInstance(
      chosenPanel.size.width,
      chosenPanel.size.height,
      chosenPanel.texture,
      horizontalPanels.length
    );

    this.scene.add(instancedMesh);

    for (let i = 0; i < horizontalPanels.length; i++) {
      const position = horizontalPanels[i].position;

      const transformationMatrix = this.createTransformationMatrix({
        position,
        normal: area.normal,
        verticalVector: area.verticalVector,
        areaCenter: area.areaCenter,
        rotation: area.currentRotation,
        selfRotate: false,
        offset,
      });

      instancedMesh.setMatrixAt(i, transformationMatrix);
    }

    instancedMesh.instanceMatrix.needsUpdate = true;
    this.selectedArea.horizontalPanelInstancedMesh = instancedMesh;

    const trashIcon = this.createTrashIcon(chosenPanel, false);
    this.selectedArea.horizontalTrashIcon = trashIcon;
    this.scene.add(trashIcon);
  }

  this.checkPanelsInSolarArea(area);
};

export const createTransformationMatrix = function ({
  position,
  normal,
  verticalVector,
  areaCenter,
  rotation,
  selfRotate = false,
  offset,
}) {
  const {
    transformationPosition,
    transformationTarget,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  transformationPosition.set(position.x, position.y, position.z);

  const transformationPositionWithOffset = transformationPosition.clone();

  if (offset) transformationPositionWithOffset.sub(offset);

  transformationTarget.copy(transformationPosition).add(normal);

  const transformationMatrixLookAt = new THREE.Matrix4().lookAt(
    transformationPosition,
    transformationTarget,
    verticalVector
  );

  transformationQuaternion.setFromRotationMatrix(transformationMatrixLookAt);

  if (rotation) {
    if (selfRotate) {
      const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
        normal,
        rotation
      );

      transformationQuaternion.premultiply(
        new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
      );
    } else {
      transformationPositionWithOffset.sub(areaCenter);

      const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
        normal,
        rotation
      );

      transformationPositionWithOffset.applyMatrix4(rotationMatrix);

      transformationQuaternion.premultiply(
        new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
      );

      transformationPositionWithOffset.add(areaCenter);
    }
  }

  transformationMatrix.compose(
    transformationPositionWithOffset,
    transformationQuaternion,
    transformationScale
  );

  return transformationMatrix;
};

export const detectIndividualPanels = function (event) {
  if (event.target.tagName !== "CANVAS") return;
  if (this.draggedIndividualPanel) return this.changeCursorToMove();

  this.setMousePosition(event);

  let intersects;

  if (this.selectedArea.verticalPanelInstancedMesh) {
    intersects = this.raycaster.intersectObject(
      this.selectedArea.verticalPanelInstancedMesh,
      true
    );

    if (intersects.length > 0) {
      const trashIntersects = this.raycaster.intersectObject(
        this.selectedArea.verticalTrashIcon,
        true
      );
      if (
        trashIntersects.length > 0 &&
        this.selectedArea.verticalTrashIcon.visible
      )
        return this.changeCursorToPointer();

      this.changeCursorToMove();
      return this.showIndividualPanelTrashIcon(intersects[0], true);
    }

    this.hideIndividualPanelTrashIcon(true);
  }

  if (this.selectedArea.horizontalPanelInstancedMesh) {
    intersects = this.raycaster.intersectObject(
      this.selectedArea.horizontalPanelInstancedMesh,
      true
    );

    if (intersects.length > 0) {
      const trashIntersects = this.raycaster.intersectObject(
        this.selectedArea.horizontalTrashIcon,
        true
      );
      if (
        trashIntersects.length > 0 &&
        this.selectedArea.horizontalTrashIcon.visible
      )
        return this.changeCursorToPointer();

      this.changeCursorToMove();
      return this.showIndividualPanelTrashIcon(intersects[0], false);
    }

    this.hideIndividualPanelTrashIcon(false);
  }
};

export const showIndividualPanelTrashIcon = function (
  intersectionPoint,
  isVertical
) {
  const {
    transformationPosition: panelPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  intersectionPoint.object.getMatrixAt(
    intersectionPoint.instanceId,
    transformationMatrix
  );

  transformationMatrix.decompose(
    panelPosition,
    transformationQuaternion,
    transformationScale
  );
  const chosenPanel = this.getPanelById(this.selectedArea.panelId, isVertical);

  const trashLength =
    this.trashSize.width *
    Math.min(chosenPanel.size.width, chosenPanel.size.height);

  const verticalVector = this.selectedArea.verticalVector.clone();
  const horizontalVector = this.selectedArea.horizontalVector.clone();

  if (this.selectedArea.currentRotation) {
    const rotationQuaternion = new THREE.Quaternion();
    rotationQuaternion.setFromAxisAngle(
      this.selectedArea.normal,
      this.selectedArea.currentRotation
    );

    verticalVector.applyQuaternion(rotationQuaternion);
    horizontalVector.applyQuaternion(rotationQuaternion);
  }

  const verticalOffset = verticalVector
    .clone()
    .multiplyScalar(chosenPanel.size.height / 2 - trashLength / 2);

  const horizontalOffset = horizontalVector
    .clone()
    .multiplyScalar(chosenPanel.size.width / 2 - trashLength / 2);

  const bottomRightPosition = panelPosition
    .clone()
    .sub(verticalOffset)
    .add(horizontalOffset);

  const trashTransformationMatrix = this.createTransformationMatrix({
    position: bottomRightPosition,
    normal: this.selectedArea.normal,
    verticalVector: this.selectedArea.verticalVector,
    areaCenter: this.selectedArea.areaCenter,
    rotation: this.selectedArea.currentRotation,
    selfRotate: true,
  });

  const {
    transformationPosition: trashPosition,
    transformationQuaternion: trashQuaternion,
    transformationScale: trashScale,
  } = this.createTransformationComponents();

  trashTransformationMatrix.decompose(
    trashPosition,
    trashQuaternion,
    trashScale
  );

  const trashIcon = isVertical
    ? this.selectedArea.verticalTrashIcon
    : this.selectedArea.horizontalTrashIcon;

  trashIcon.position.copy(trashPosition);
  trashIcon.quaternion.copy(trashQuaternion);

  trashIcon.visible = true;
};

export const hideIndividualPanelTrashIcon = function (isVertical) {
  const trashIcon = isVertical
    ? this.selectedArea.verticalTrashIcon
    : this.selectedArea.horizontalTrashIcon;

  if (trashIcon) trashIcon.visible = false;
};

export const hideAllIndividualPanelsTrashIcons = function () {
  const filteredAreas = this.areas.filter(
    (area) => area.verticalTrashIcon || area.horizontalTrashIcon
  );
  for (let area of filteredAreas) {
    if (area.verticalTrashIcon) area.verticalTrashIcon.visible = false;
    if (area.horizontalTrashIcon) area.horizontalTrashIcon.visible = false;
  }
};

export const checkIndividualPanelClicked = function (event) {
  this.setMousePosition(event);

  let intersects;

  if (this.selectedArea.verticalPanelInstancedMesh) {
    intersects = this.raycaster.intersectObject(
      this.selectedArea.verticalPanelInstancedMesh,
      true
    );

    if (intersects.length > 0) {
      const trashIntersects = this.raycaster.intersectObject(
        this.selectedArea.verticalTrashIcon,
        true
      );
      if (trashIntersects.length > 0 && trashIntersects[0].object.visible) {
        this.removeIndividualPanel(intersects[0].instanceId, true);
        return true;
      }
    }
  }

  if (this.selectedArea.horizontalPanelInstancedMesh) {
    intersects = this.raycaster.intersectObject(
      this.selectedArea.horizontalPanelInstancedMesh,
      true
    );

    if (intersects.length > 0) {
      const trashIntersects = this.raycaster.intersectObject(
        this.selectedArea.horizontalTrashIcon,
        true
      );
      if (trashIntersects.length > 0 && trashIntersects[0].object.visible) {
        this.removeIndividualPanel(intersects[0].instanceId, false);
        return true;
      }
    }
  }
};

export const removeIndividualPanel = function (index, isVertical) {
  if (this.active === 3) this.holdIndividualPanelPlacement();

  const instancedMeshToReplace = isVertical
    ? this.selectedArea.verticalPanelInstancedMesh
    : this.selectedArea.horizontalPanelInstancedMesh;

  this.hideIndividualPanelTrashIcon(isVertical);

  if (instancedMeshToReplace.instancesCount > 1) {
    const chosenPanel = this.getPanelById(
      this.selectedArea.panelId,
      isVertical
    );
    const instancedMesh = this.createPanelInstance(
      chosenPanel.size.width,
      chosenPanel.size.height,
      chosenPanel.texture,
      instancedMeshToReplace.instancesCount - 1
    );

    this.scene.add(instancedMesh);

    this.replacePanelInstance(instancedMeshToReplace, instancedMesh, index);

    if (isVertical) {
      this.selectedArea.verticalPanelInstancedMesh = instancedMesh;
    } else {
      this.selectedArea.horizontalPanelInstancedMesh = instancedMesh;
    }
  } else {
    this.removeObjectFromScene(instancedMeshToReplace);

    if (isVertical) {
      this.selectedArea.verticalPanelInstancedMesh = null;
    } else {
      this.selectedArea.horizontalPanelInstancedMesh = null;
    }
  }

  this.updateIndividualPanelsList(isVertical);

  this.updateIndividualPanelsObject(this.selectedArea);

  this.checkPanelsInSolarArea(this.selectedArea);

  this.checkPanelNumberAndVisibility(this.selectedArea);

  if (this.active === 3) {
    this.unholdIndividualPanelPlacement();
    this.changeCursorToCrosshair();
  }
};

export const holdIndividualPanelPlacement = function () {
  document.removeEventListener("click", this.addIndividualPanel, false);
};

export const unholdIndividualPanelPlacement = function () {
  document.addEventListener("click", this.addIndividualPanel, false);
};

export const updateIndividualPanelsList = function (isVertical) {
  if (this.sample) return;

  const instancedMesh = isVertical
    ? this.selectedArea.verticalPanelInstancedMesh
    : this.selectedArea.horizontalPanelInstancedMesh;

  const newPanels = isVertical
    ? this.selectedArea.individual_panels.filter(
        (panel) => panel.orientation === "horizontal"
      )
    : this.selectedArea.individual_panels.filter(
        (panel) => panel.orientation === "vertical"
      );

  if (!instancedMesh) {
    this.selectedArea.individual_panels = newPanels;
    return;
  }

  const offset = this.selectedArea.normal
    .clone()
    .multiplyScalar(this.selectedArea.offset / 100);

  const {
    transformationPosition: tempPosition,
    transformationQuaternion: tempQuaternion,
    transformationScale: tempScale,
    transformationMatrix: transformationMatrix,
  } = this.createTransformationComponents();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    newPanels.push({
      position: tempPositionWithoutOffset,
      orientation: isVertical ? "vertical" : "horizontal",
    });
  }

  this.selectedArea.individual_panels = newPanels;
};

export const validateIndividualPanels = function (area) {
  const offset = area.normal.clone().multiplyScalar(area.offset / 100);

  if (area.verticalPanelInstancedMesh) {
    this.hideIndividualPanelsOutsideArea(
      area.verticalPanelInstancedMesh,
      area.verticalTestPanel,
      area.ground,
      area.normal,
      area.verticalVector,
      area.areaCenter,
      area.currentRotation,
      offset,
      area.innerPlane.points,
      area.innerPlane.edges,
      area.simulatedCamera,
      true
    );
  }

  if (area.horizontalPanelInstancedMesh)
    this.hideIndividualPanelsOutsideArea(
      area.horizontalPanelInstancedMesh,
      area.horizontalTestPanel,
      area.ground,
      area.normal,
      area.verticalVector,
      area.areaCenter,
      area.currentRotation,
      offset,
      area.innerPlane.points,
      area.innerPlane.edges,
      area.simulatedCamera,
      false
    );

  for (let i = 0; i < area.restrictedAreas.length; i++) {
    this.checkIndividualPanelIntersectionWithRestrictedArea(area, i);
  }

  this.checkIndividualPanelsIntersection(area);
};

export const hideIndividualPanelsOutsideArea = function (
  instancedMesh,
  testPanel,
  ground,
  normal,
  verticalVector,
  areaCenter,
  currentRotation,
  offset,
  innerPlanePoints,
  innerPlaneEdges,
  simulatedCamera,
  isVertical
) {
  const {
    transformationPosition: tempPosition,
    transformationQuaternion: tempQuaternion,
    transformationScale: tempScale,
    transformationMatrix: transformationMatrix,
  } = this.createTransformationComponents();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    if (
      !this.checkIndividualPanelInsideArea(
        testPanel,
        tempPositionWithoutOffset,
        ground,
        normal,
        verticalVector,
        areaCenter,
        currentRotation,
        innerPlanePoints,
        innerPlaneEdges,
        simulatedCamera
      )
    ) {
      this.removeIndividualPanel(i, isVertical);
    }
  }
};

export const checkIndividualPanelInsideArea = function (
  testPanel,
  position,
  ground,
  normal,
  verticalVector,
  areaCenter,
  currentRotation,
  innerPlanePoints,
  innerPlaneEdges,
  simulatedCamera
) {
  const transformationMatrix = this.createTransformationMatrix({
    position,
    normal,
    verticalVector,
    areaCenter,
    rotation: currentRotation,
    selfRotate: true,
  });

  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
  } = this.createTransformationComponents();

  transformationMatrix.decompose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  const { panelVertices, panelEdges } = this.createPanelAt({
    panel: testPanel,
    pos: transformationPosition,
    rotation: transformationQuaternion,
    ground,
    normal,
  });

  return this.isRectangleWithinPoints(
    panelVertices,
    panelEdges,
    innerPlanePoints,
    innerPlaneEdges,
    simulatedCamera
  );
};

export const changePanelTypeForIndividualPanels = function (area) {
  if (area.verticalPanelInstancedMesh) {
    const panelType = this.getPanelById(area.panelId, true);
    const instancedMeshToReplace = area.verticalPanelInstancedMesh;

    const instancedMesh = this.createPanelInstance(
      panelType.size.width,
      panelType.size.height,
      panelType.texture,
      instancedMeshToReplace.instancesCount
    );
    const individualTestPanel = this.createTempPanel(panelType);

    this.scene.add(instancedMesh);

    this.replacePanelInstance(instancedMeshToReplace, instancedMesh);

    area.verticalPanelInstancedMesh = instancedMesh;
    area.verticalTestPanel = individualTestPanel;

    if (area.verticalTrashIcon)
      this.updateTrashIcon(area.verticalTrashIcon, panelType);

    if (area.verticalPanelOutline) {
      this.removeObjectFromScene(area.verticalPanelOutline);
      area.verticalPanelOutline = null;
    }
  }

  if (area.horizontalPanelInstancedMesh) {
    const panelType = this.getPanelById(area.panelId, false);
    const instancedMeshToReplace = area.horizontalPanelInstancedMesh;

    const instancedMesh = this.createPanelInstance(
      panelType.size.width,
      panelType.size.height,
      panelType.texture,
      instancedMeshToReplace.instancesCount
    );
    const individualTestPanel = this.createTempPanel(panelType);

    this.scene.add(instancedMesh);

    this.replacePanelInstance(instancedMeshToReplace, instancedMesh);

    area.horizontalPanelInstancedMesh = instancedMesh;
    area.horizontalTestPanel = individualTestPanel;

    if (area.horizontalTrashIcon)
      this.updateTrashIcon(area.horizontalTrashIcon, panelType);

    if (area.horizontalPanelOutline) {
      this.removeObjectFromScene(area.horizontalPanelOutline);
      area.horizontalPanelOutline = null;
    }
  }
};

export const checkIndividualPanelIntersectionWithRestrictedArea = function (
  area,
  restrictedAreaIndex
) {
  const offset = area.normal.clone().multiplyScalar(area.offset / 100);
  const restrictedArea = area.restrictedAreas[restrictedAreaIndex];

  if (area.verticalPanelInstancedMesh)
    this.hideIndividualPanelsIntersectingRestrictedArea(
      area.verticalPanelInstancedMesh,
      area.verticalTestPanel,
      area.ground,
      area.normal,
      offset,
      area.simulatedCamera,
      restrictedArea,
      true
    );

  if (area.horizontalPanelInstancedMesh)
    this.hideIndividualPanelsIntersectingRestrictedArea(
      area.horizontalPanelInstancedMesh,
      area.horizontalTestPanel,
      area.ground,
      area.normal,
      offset,
      area.simulatedCamera,
      restrictedArea,
      false
    );
};

export const hideIndividualPanelsIntersectingRestrictedArea = function (
  instancedMesh,
  testPanel,
  ground,
  normal,
  offset,
  simulatedCamera,
  restrictedArea,
  isVertical
) {
  const {
    transformationPosition: tempPosition,
    transformationQuaternion: tempQuaternion,
    transformationScale: tempScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    const { panelVertices, panelEdges } = this.createPanelAt({
      panel: testPanel,
      pos: tempPositionWithoutOffset,
      rotation: tempQuaternion,
      ground,
      normal,
    });

    if (
      !this.checkPanelAgainstRestrictedAreas(
        panelEdges,
        panelVertices,
        [restrictedArea],
        simulatedCamera
      )
    ) {
      this.removeIndividualPanel(i, isVertical);
    }
  }
};

export const checkIndividualPanelsIntersection = function (area) {
  const offset = area.normal.clone().multiplyScalar(area.offset / 100);

  if (area.verticalPanelInstancedMesh) {
    this.hideIntersectingIndividualPanels({
      instancedMesh: area.verticalPanelInstancedMesh,
      testPanel: area.verticalTestPanel,
      otherInstancedMesh: area.verticalPanelInstancedMesh,
      otherTestPanel: area.verticalTestPanel,
      ground: area.ground,
      normal: area.normal,
      offset,
      simulatedCamera: area.simulatedCamera,
      isVertical: true,
      skipCurrentIndex: true,
    });
  }

  if (area.horizontalPanelInstancedMesh) {
    this.hideIntersectingIndividualPanels({
      instancedMesh: area.horizontalPanelInstancedMesh,
      testPanel: area.horizontalTestPanel,
      otherInstancedMesh: area.horizontalPanelInstancedMesh,
      otherTestPanel: area.horizontalTestPanel,
      ground: area.ground,
      normal: area.normal,
      offset,
      simulatedCamera: area.simulatedCamera,
      isVertical: false,
      skipCurrentIndex: true,
    });
  }

  if (area.verticalPanelInstancedMesh && area.horizontalPanelInstancedMesh) {
    this.hideIntersectingIndividualPanels({
      instancedMesh: area.verticalPanelInstancedMesh,
      testPanel: area.verticalTestPanel,
      otherInstancedMesh: area.horizontalPanelInstancedMesh,
      otherTestPanel: area.horizontalTestPanel,
      ground: area.ground,
      normal: area.normal,
      offset,
      simulatedCamera: area.simulatedCamera,
      isVertical: true,
      skipCurrentIndex: false,
    });
  }
};

export const hideIntersectingIndividualPanels = function ({
  instancedMesh,
  testPanel,
  otherTestPanel,
  otherInstancedMesh,
  ground,
  normal,
  offset,
  simulatedCamera,
  isVertical,
  skipCurrentIndex,
}) {
  const {
    transformationPosition: tempPosition,
    transformationQuaternion: tempQuaternion,
    transformationScale: tempScale,
    transformationMatrix: transformationMatrix,
  } = this.createTransformationComponents();

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    tempPositionWithoutOffset.add(offset);

    const { panelVertices, panelEdges } = this.createPanelAt({
      panel: testPanel,
      pos: tempPositionWithoutOffset,
      rotation: tempQuaternion,
      ground,
      normal,
    });

    if (
      !this.checkPanelAgainstIndividualPanels(
        panelVertices,
        panelEdges,
        otherInstancedMesh,
        otherTestPanel,
        offset,
        ground,
        normal,
        simulatedCamera,
        skipCurrentIndex ? i : -1
      )
    ) {
      this.removeIndividualPanel(i, isVertical);
    }
  }
};

export const dragIndividualPanelStart = function (event) {
  if (this.disableClick(event)) return;

  if (this.mouseOverTrash()) return;

  this.setMousePosition(event);

  let intersects;

  if (this.selectedArea.verticalPanelInstancedMesh) {
    intersects = this.raycaster.intersectObject(
      this.selectedArea.verticalPanelInstancedMesh,
      true
    );

    if (intersects.length > 0) {
      const intersectionPoint = new THREE.Vector3();
      const hasIntersection = this.raycaster.ray.intersectPlane(
        this.selectedArea.infinitePlane,
        intersectionPoint
      );

      if (!hasIntersection) {
        return;
      }

      const areaPlane = this.selectedArea.trianglePlane;
      const projectedPoint = new THREE.Vector3();
      areaPlane.projectPoint(intersectionPoint, projectedPoint);

      const originalPosition = this.getInstancePosition(
        this.selectedArea.verticalPanelInstancedMesh,
        intersects[0].instanceId
      );

      const mouseOffset = originalPosition.clone().sub(projectedPoint);

      this.setRenderingPriority(this.selectedArea.verticalPanelInstancedMesh);

      if (this.selectedArea.verticalPanelOutline) {
        this.updatePanelOutline({
          instancedMesh: this.selectedArea.verticalPanelInstancedMesh,
          index: intersects[0].instanceId,
          testPanel: this.selectedArea.verticalTestPanel,
          ground: this.selectedArea.ground,
          normal: this.selectedArea.normal,
          outline: this.selectedArea.verticalPanelOutline,
        });
      } else {
        const outline = this.createPanelOutline({
          instancedMesh: this.selectedArea.verticalPanelInstancedMesh,
          index: intersects[0].instanceId,
          testPanel: this.selectedArea.verticalTestPanel,
          ground: this.selectedArea.ground,
          normal: this.selectedArea.normal,
        });
        this.scene.add(outline);

        this.selectedArea.verticalPanelOutline = outline;
      }

      this.draggedIndividualPanel = {
        instancedMesh: this.selectedArea.verticalPanelInstancedMesh,
        testPanel: this.selectedArea.verticalTestPanel,
        isVertical: true,
        index: intersects[0].instanceId,
        originalPosition,
        mouseOffset,
        outline: this.selectedArea.verticalPanelOutline,
      };

      this.holdCursorChanges();

      document.addEventListener("mousemove", this.dragIndividualPanel);
      document.addEventListener("mouseup", this.dragIndividualPanelEnd);
      return;
    }
  }

  if (this.selectedArea.horizontalPanelInstancedMesh) {
    intersects = this.raycaster.intersectObject(
      this.selectedArea.horizontalPanelInstancedMesh,
      true
    );

    if (intersects.length > 0) {
      const intersectionPoint = new THREE.Vector3();
      const hasIntersection = this.raycaster.ray.intersectPlane(
        this.selectedArea.infinitePlane,
        intersectionPoint
      );

      if (!hasIntersection) {
        return;
      }

      const areaPlane = this.selectedArea.trianglePlane;
      const projectedPoint = new THREE.Vector3();
      areaPlane.projectPoint(intersectionPoint, projectedPoint);

      const originalPosition = this.getInstancePosition(
        this.selectedArea.horizontalPanelInstancedMesh,
        intersects[0].instanceId
      );

      const mouseOffset = originalPosition.clone().sub(projectedPoint);

      this.setRenderingPriority(this.selectedArea.horizontalPanelInstancedMesh);

      if (this.selectedArea.horizontalPanelOutline) {
        this.updatePanelOutline({
          instancedMesh: this.selectedArea.horizontalPanelInstancedMesh,
          index: intersects[0].instanceId,
          testPanel: this.selectedArea.horizontalTestPanel,
          ground: this.selectedArea.ground,
          normal: this.selectedArea.normal,
          outline: this.selectedArea.horizontalPanelOutline,
        });
      } else {
        const outline = this.createPanelOutline({
          instancedMesh: this.selectedArea.horizontalPanelInstancedMesh,
          index: intersects[0].instanceId,
          testPanel: this.selectedArea.horizontalTestPanel,
          ground: this.selectedArea.ground,
          normal: this.selectedArea.normal,
        });
        this.scene.add(outline);
        this.selectedArea.horizontalPanelOutline = outline;
      }

      this.draggedIndividualPanel = {
        instancedMesh: this.selectedArea.horizontalPanelInstancedMesh,
        testPanel: this.selectedArea.horizontalTestPanel,
        isVertical: false,
        index: intersects[0].instanceId,
        originalPosition,
        mouseOffset,
        isValid: true,
        outline: this.selectedArea.horizontalPanelOutline,
      };

      this.holdCursorChanges();

      document.addEventListener("mousemove", this.dragIndividualPanel);
      document.addEventListener("mouseup", this.dragIndividualPanelEnd);
      return;
    }
  }
};

export const dragIndividualPanel = function (event) {
  this.setMousePosition(event);
  const intersectionPoint = new THREE.Vector3();
  const hasIntersection = this.raycaster.ray.intersectPlane(
    this.selectedArea.infinitePlane,
    intersectionPoint
  );

  if (!hasIntersection) {
    return;
  }

  const areaPlane = this.selectedArea.trianglePlane;
  const projectedPoint = new THREE.Vector3();
  intersectionPoint.add(this.draggedIndividualPanel.mouseOffset);

  areaPlane.projectPoint(intersectionPoint, projectedPoint);

  const offset = this.selectedArea.normal
    .clone()
    .multiplyScalar(this.selectedArea.offset / 100);

  const newPosition = projectedPoint.clone().sub(offset);
  this.setInstancePosition(
    this.draggedIndividualPanel.instancedMesh,
    this.draggedIndividualPanel.index,
    newPosition
  );

  if (
    !this.validatePanelPosition(
      projectedPoint,
      this.draggedIndividualPanel.isVertical,
      this.selectedArea,
      this.draggedIndividualPanel.index
    )
  ) {
    if (this.draggedIndividualPanel.isValid) {
      this.draggedIndividualPanel.isValid = false;
      this.setOutlineColor(
        this.draggedIndividualPanel.outline,
        RESTRICTED_AREA_COLOR
      );
    }
  } else {
    if (!this.draggedIndividualPanel.isValid) {
      this.draggedIndividualPanel.isValid = true;
      this.setOutlineColor(this.draggedIndividualPanel.outline, OUTLINR_COLOR);
    }
  }

  this.updatePanelOutline({
    instancedMesh: this.draggedIndividualPanel.instancedMesh,
    index: this.draggedIndividualPanel.index,
    testPanel: this.draggedIndividualPanel.testPanel,
    outline: this.draggedIndividualPanel.outline,
    ground: this.selectedArea.ground,
    normal: this.selectedArea.normal,
  });
};

export const dragIndividualPanelEnd = function () {
  const newPosition = this.getInstancePosition(
    this.draggedIndividualPanel.instancedMesh,
    this.draggedIndividualPanel.index
  );

  const offset = this.selectedArea.normal
    .clone()
    .multiplyScalar(this.selectedArea.offset / 100);

  const newPositionWithoutOffset = newPosition.clone().add(offset);

  this.hidePanelOutline(this.draggedIndividualPanel.outline);

  if (
    !this.validatePanelPosition(
      newPositionWithoutOffset,
      this.draggedIndividualPanel.isVertical,
      this.selectedArea,
      this.draggedIndividualPanel.index
    )
  ) {
    this.setInstancePosition(
      this.draggedIndividualPanel.instancedMesh,
      this.draggedIndividualPanel.index,
      this.draggedIndividualPanel.originalPosition
    );

    this.draggedIndividualPanel.isValid = true;
  } else {
    this.updateIndividualPanelsList(this.draggedIndividualPanel.isVertical);
    this.updateIndividualPanelsObject(this.selectedArea);
    this.checkPanelsInSolarArea(this.selectedArea);
  }

  document.removeEventListener("mousemove", this.dragIndividualPanel, false);
  document.removeEventListener("mouseup", this.dragIndividualPanelEnd, false);

  this.resetRenderingPriority(this.draggedIndividualPanel.instancedMesh);
  this.draggedIndividualPanel = null;
  setTimeout(() => {
    this.unholdCursorChanges();
  }, 0);
};

export const getInstancePosition = function (instancedMesh, index) {
  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  instancedMesh.getMatrixAt(index, transformationMatrix);
  transformationMatrix.decompose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  return transformationPosition;
};

export const setInstancePosition = function (instancedMesh, index, position) {
  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  instancedMesh.getMatrixAt(index, transformationMatrix);
  transformationMatrix.decompose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  transformationPosition.set(position.x, position.y, position.z);

  transformationMatrix.compose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  instancedMesh.setMatrixAt(index, transformationMatrix);
  instancedMesh.instanceMatrix.needsUpdate = true;

  instancedMesh.computeBVH();
};

export const holdCursorChanges = function () {
  this.disableDefaultNavigation();
  this.hideIndividualPanelTrashIcon(this.draggedIndividualPanel.isVertical);
  this.hideAreaLabelIcons();
  this.changeCursorToMove();

  document.removeEventListener(
    "mousemove",
    this.updateCursorForIndividualPlacement,
    false
  );
  document.removeEventListener("click", this.addIndividualPanel, false);
};

export const unholdCursorChanges = function () {
  if (this.active === 2) return this.showAreaLabelIcons();

  document.addEventListener("click", this.addIndividualPanel, false);

  document.addEventListener(
    "mousemove",
    this.updateCursorForIndividualPlacement,
    false
  );
};

export const changePanelInstanceColor = function (instancedMesh, index) {
  instancedMesh.setColorAt(index, new THREE.Color(1, 0, 0));
};

export const resetPanelInstanceColor = function (instancedMesh, index) {
  instancedMesh.setColorAt(index, new THREE.Color(1, 1, 1));
};

export const mouseOverTrash = function () {
  if (this.selectedArea.verticalTrashIcon) {
    const trashIntersects = this.raycaster.intersectObject(
      this.selectedArea.verticalTrashIcon,
      true
    );
    if (trashIntersects.length > 0) return true;
  }

  if (this.selectedArea.horizontalTrashIcon) {
    const trashIntersects = this.raycaster.intersectObject(
      this.selectedArea.horizontalTrashIcon,
      true
    );
    if (trashIntersects.length > 0) return true;
  }

  return false;
};

export const enableIndividualPlacementListeners = function () {
  document.addEventListener("mousemove", this.detectIndividualPanels, false);
  document.addEventListener("mousedown", this.dragIndividualPanelStart, false);
};

export const disableIndividualPlacementListeners = function () {
  document.removeEventListener("mousemove", this.detectIndividualPanels, false);
  document.removeEventListener(
    "mousedown",
    this.dragIndividualPanelStart,
    false
  );
};

export const setRenderingPriority = function (instancedMesh) {
  instancedMesh.material.depthTest = false;
  instancedMesh.renderingOrder = RENDERING_ORDER.INDIVIDUAL_PANEL;
};

export const resetRenderingPriority = function (instancedMesh) {
  instancedMesh.material.depthTest = true;
  instancedMesh.renderingOrder = 0;
};

export const createPanelOutline = function ({
  instancedMesh,
  index,
  testPanel,
  ground,
  normal,
}) {
  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  instancedMesh.getMatrixAt(index, transformationMatrix);

  transformationMatrix.decompose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  const { panelEdges } = this.createPanelAt({
    panel: testPanel,
    pos: transformationPosition,
    rotation: transformationQuaternion,
    ground,
    normal,
  });

  const edgesWithoutDiagonal = this.eliminateDiagonalFromEdges(panelEdges);

  const panelVertices = [];

  for (let edge of edgesWithoutDiagonal) {
    panelVertices.push(edge[0]);
  }

  const outline = this.createClosedThickLine({
    points: panelVertices.map((point) => {
      return {
        position: point,
      };
    }),
    color: OUTLINR_COLOR,
    thickness: 2.0,
  });

  outline.material.depthTest = false;
  outline.renderOrder = RENDERING_ORDER.INDIVIDUAL_PANEL_OUTLINE;

  return outline;
};

export const updatePanelOutline = function ({
  instancedMesh,
  index,
  testPanel,
  ground,
  normal,
  outline,
}) {
  const {
    transformationPosition,
    transformationQuaternion,
    transformationScale,
    transformationMatrix,
  } = this.createTransformationComponents();

  instancedMesh.getMatrixAt(index, transformationMatrix);

  transformationMatrix.decompose(
    transformationPosition,
    transformationQuaternion,
    transformationScale
  );

  const { panelEdges } = this.createPanelAt({
    panel: testPanel,
    pos: transformationPosition,
    rotation: transformationQuaternion,
    ground,
    normal,
  });

  const edgesWithoutDiagonal = this.eliminateDiagonalFromEdges(panelEdges);

  const panelVertices = [];

  for (let edge of edgesWithoutDiagonal) {
    panelVertices.push(edge[0]);
  }

  this.updateClosedLinePosition({
    line: outline,
    points: panelVertices.map((point) => {
      return {
        position: point,
      };
    }),
  });

  if (!outline.visible) outline.visible = true;
};

export const hidePanelOutline = function (outline) {
  this.setOutlineColor(outline, OUTLINR_COLOR);
  outline.visible = false;
};

export const eliminateDiagonalFromEdges = function (edges) {
  let maxLength = 0;
  let diagonalEdgeIndex = -1;
  for (let i = 0; i < edges.length; i++) {
    const length = calculateEdgeLength(edges[i]);
    if (length > maxLength) {
      maxLength = length;
      diagonalEdgeIndex = i;
    }
  }

  if (diagonalEdgeIndex !== -1) {
    edges.splice(diagonalEdgeIndex, 1);
  }

  if (edges.length > 4) this.eliminateDiagonalFromEdges(edges);

  return edges;
};

export const calculateEdgeLength = function (edge) {
  const dx = edge[0].x - edge[1].x;
  const dy = edge[0].y - edge[1].y;
  const dz = edge[0].z - edge[1].z;
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
};

export const setOutlineColor = function (outline, color) {
  outline.material.color.setHex(color);
};
