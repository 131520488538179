<template>
  <div class="main-wrapper">
    <div class="pannel-wrapper">
      <div class="solar-wrapper">
        <div class="solar-wrapper-header">
          <img
            v-if="!anonymousUser"
            src="@/assets/icons/newEyeOpenIcon.svg"
            alt="eye"
            class="eye"
            @click="changeShowFlag"
            v-show="areasAreShown"
          />
          <img
            v-if="!anonymousUser"
            src="@/assets/icons/newEyeClosedIcon.svg"
            alt="eye"
            class="eye"
            @click="changeShowFlag"
            v-show="!areasAreShown"
          />
          <div
            class="section-header-right-side"
            @click="toggleSolars"
            :class="{ inactiveGray: !areasAreShown }"
          >
            <p class="cluster-title">
              {{ $t("sidebar.solar") }}
            </p>
            <img
              src="@/assets/icons/newArrowUp.svg"
              alt="triangle"
              class="arrow"
              v-show="showSolars"
            />
            <img
              src="@/assets/icons/newArrowDown.svg"
              alt="triangle"
              class="arrow"
              v-show="!showSolars"
            />
          </div>
        </div>
        <div v-if="showSolars" class="solar-details-box">
          <div
            class="solar-inside-wrapper"
            v-for="(solar, index) in solarGroupDetails"
            :key="index"
          >
            <div
              class="solar-header"
              :class="{
                active: index == selectedSolar && solar.show,
                grayClass: !areasAreShown,
                expand: index == selectedSolar,
              }"
            >
              <p @click="showSolarDetails(solar, index)">
                {{ $t("sidebar.solargroup") }} <span>{{ index + 1 }}</span>
              </p>
              <div class="anotation-image-wrapper">
                <img
                  src="@/assets/icons/newTrashIcon.svg"
                  alt="trash"
                  class="trash"
                  v-if="showTrashIcon && solar.show && !anonymousUser"
                  @click="handleDeleteSolarGroup(solar)"
                />

                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="hideThisSolarArea(solar, index)"
                  v-if="solar.show && areasAreShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="showThisSolarArea(solar, index)"
                  v-if="!solar.show && areasAreShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="hideThisSolarArea(solar, index)"
                  v-if="solar.show && !areasAreShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="showThisSolarArea(solar, index)"
                  v-if="!solar.show && !areasAreShown && !anonymousUser"
                />
              </div>
            </div>
            <div
              class="solar-details"
              v-if="index == selectedSolar"
              :class="{ grayClass: !areasAreShown || !solar.show }"
            >
              <div class="details-text">
                <p>{{ $t("sidebar.totalPanelsArea") }}</p>
                <span> {{ totalPanelsArea }} </span>
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.slope") }}</p>
                <span>{{ solar.angle }}°</span>
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.amountofpanels") }}</p>
                <span>{{ totalNumberOfPanels }}</span>
              </div>
              <div style="padding-right: 10px">
                <span class="badge badge-info"
                  >{{ solar.transparencyLevel }}%</span
                >
                <q-slider
                  v-model="solar.transparencyLevel"
                  :min="0"
                  :max="100"
                  color="emer500"
                  @change="(value) => updateTransparencyLevel(solar, value)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script setup>
import { onMounted, ref, watch } from "vue";

import API from "@/api/API";

const props = defineProps([
  "solarGroupDetails",
  "hideAreas",
  "showAreas",
  "hideSingleArea",
  "showSingleArea",
  "sample",
  "updateTransparencyLevel",
  "config",
  "setVisibilityInStore",
  "objectIsloaded",
  "removeSolarGroup",
  "unselectSolarArea",
  "anonymousUser",
  "totalNumberOfPanels",
  "totalPanelsArea",
  "active",
]);
const emits = defineEmits(["updateSelectedArea", "setColapsedSections"]);

const areasAreShown = ref(
  !(
    props.solarGroupDetails.every((v) => v.show === true) ||
    props.solarGroupDetails.every((v) => v.show === false)
  )
);
const showTrashIcon = ref(true);

const changeShowFlag = () => {
  const newValue = !areasAreShown.value;

  props.setVisibilityInStore("solar_groups_visible", newValue);

  if (newValue) {
    props.showAreas();
    showTrashIcon.value = true;
  } else {
    props.hideAreas();
    showTrashIcon.value = false;
  }

  areasAreShown.value = newValue;
};

function hideThisSolarArea(solar, index) {
  if (areasAreShown.value) {
    props.hideSingleArea(solar, true);
  }

  const newValue = !solar.show;
  setSolarAreaAppearance(solar.id, newValue);
  solar.show = newValue;
}

function showThisSolarArea(solar, index) {
  if (areasAreShown.value) {
    props.showSingleArea(solar);
  }
  const newValue = !solar.show;
  setSolarAreaAppearance(solar.id, newValue);
  solar.show = newValue;
}

const showSolars = ref(false);
const selectedSolar = ref(null);

function toggleSolars() {
  if (props.solarGroupDetails.length) {
    showSolars.value = !showSolars.value;
  }
  if (showSolars.value) {
    emits("setColapsedSections", "solarComponent");
    selectedSolar.value = null;
  }
}
function colapseFromParent() {
  if (props.solarGroupDetails.length) {
    showSolars.value = false;
  }
}

function showSolarDetails(solar, number) {
  if (selectedSolar.value != number) {
    // Unselect previous area before selecting new one
    props.unselectSolarArea(true);
    selectedSolar.value = number;
    emits("updateSelectedArea", solar);
  } else {
    selectedSolar.value = null;
    props.unselectSolarArea(true);
  }
}

async function setSolarAreaAppearance(id, show) {
  if (props.sample) {
    return;
  }
  return await API.airteam3DViewer.patchObject(id, show);
}

function handleCreateSolarGroup() {
  areasAreShown.value = true;
  if (props.solarGroupDetails.length) {
    showSolars.value = true;
  }
  if (showSolars.value) {
    emits("setColapsedSections", "solarComponent");
    props.setVisibilityInStore("solar_groups_visible", showSolars.value);
  }
}
function selectAreaOnSidebar(selectedSolarArea) {
  if (!showSolars.value) {
    toggleSolars();
  }
  let selectedSolarAreaIndex = props.solarGroupDetails.findIndex(
    (element) => element.plane.id === selectedSolarArea.plane.id
  );

  if (selectedSolar.value != selectedSolarAreaIndex) {
    selectedSolar.value = selectedSolarAreaIndex;
  } else {
    if (props.active == 6) {
      return;
    }

    selectedSolar.value = null;
    props.unselectSolarArea(true);
  }
}
function unselectSolarAreaOnSidebar() {
  selectedSolar.value = null;
}

defineExpose({
  colapseFromParent,
  handleCreateSolarGroup,
  selectAreaOnSidebar,
  unselectSolarAreaOnSidebar,
  showSolarFlag,
});

function handleDeleteSolarGroup(solar) {
  props.removeSolarGroup(solar);
}

function showSolarFlag() {
  if (props.solarGroupDetails.length) {
    props.setVisibilityInStore("solar_groups_visible", true);

    areasAreShown.value = true;
  }
}

// Watch for changes in props.areaDetails
watch(
  () => props.solarGroupDetails.length,
  (newLength) => {
    if (newLength > 0) {
      showSolars.value = true;
    }
  }
);

watch(
  () => props.objectIsloaded,
  (newValue) => {
    if (newValue) {
      if (props.config.solar_groups_visible === false) {
        // If solar_groups_visible is false in config, hide everything
        areasAreShown.value = false;
        props.hideAreas();
        showTrashIcon.value = false;
      } else {
        // If solar_groups_visible is true, show areas based on their individual states
        areasAreShown.value = true;
        props.solarGroupDetails.forEach((solar) => {
          if (solar.show) {
            props.showSingleArea(solar);
          } else {
            props.hideSingleArea(solar, true);
          }
        });
        showTrashIcon.value = true;
      }
    }
  }
);
</script>

<style lang="scss" scoped>
.main-wrapper {
  .pannel-wrapper {
    .solar-wrapper {
      .solar-wrapper-header {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 1rem 0;
        .section-header-right-side {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
        .section-header-right-side.inactiveGray {
          p {
            color: gray;
          }
          img {
            filter: grayscale(1) brightness(0.5); /* Initial gray color */
          }
        }
        p {
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: bold;
          line-height: 19px;
          color: white;
          letter-spacing: 0.03em;
        }
        img {
          height: 12px;
          width: 18px;
          cursor: pointer;
        }
        .trash,
        .arrow {
          height: 20px;
          width: 20px;
        }
      }
      .solar-details-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .solar-inside-wrapper {
          .solar-header.active.expand {
            border: 1px solid #23e7a5;
            border-radius: 10px 10px 0 0;
            border-bottom: none;
            margin-bottom: 1px;
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .solar-header.active:hover {
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .solar-header.expand {
            border: none;
            margin-bottom: 1px;
            border-radius: 10px 10px 0 0;
          }
          .solar-header:hover {
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
              img {
                filter: grayscale(1) brightness(0.7);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .solar-header {
            background: #27292c;
            border: 1px solid #3b3c3d;
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 1rem 0.5rem;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            p.grayCustome {
              color: gray;
            }
            .anotation-image-wrapper {
              display: none;
            }
            p {
              cursor: pointer;
              color: white;
            }
            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
              filter: brightness(0) invert(1);
            }
          }
          .solar-details {
            background: #27292c;
            border: 1px solid #23e7a5;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 5px 5px 8px 10px;
            .details-text {
              display: flex;
              justify-content: space-between;
            }
          }
          .solar-header.grayClass:hover {
            img {
              filter: grayscale(1) brightness(0.5);
            }
          }
          .solar-header.grayClass {
            margin-bottom: 1px;
            p {
              color: gray;
            }
            img {
              filter: grayscale(1) brightness(0.5);
            }
            img:hover {
              filter: brightness(0) invert(1); /* White color on hover */
            }
            .trash {
              display: none;
            }
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .solar-header.active.grayClass {
            border: none;
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(0.5); /* gray color  */
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .solar-details.grayClass {
            border: none;
            p {
              color: gray;
            }
            span {
              color: gray;
            }
          }
        }
      }
    }
  }
}

.eye:hover {
  filter: brightness(0) invert(1); /* White color on hover */
}

@media (max-width: 1600px) {
  .main-wrapper {
    .pannel-wrapper {
      .solar-wrapper {
        .solar-details-box {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .solar-inside-wrapper {
            .solar-header.active {
              border: none;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
              img {
                width: 18px;
                height: 18px;
                cursor: pointer;
              }
            }
            .solar-details {
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
