import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import auth from "@/store/modules/auth";
import customPanels from "@/store/modules/customPanels";
import section from "@/store/modules/sections";

const persist = new VuexPersist({
  key: "ffsolar",
  storage: localStorage,
  reducer: (state) => ({
    auth: state.auth,
    customPanels: state.customPanels,
    section: {
      config: {
        project_id: state.section.config.project_id,
        solar_groups_visible: state.section.config.solar_groups_visible,
        distances_visible: state.section.config.distances_visible,
        measurements_visible: state.section.config.measurements_visible,
      },
    },
  }),
  filter: (mutation) => {
    const allowedMutations = ["SET_CONFIG", "SET_VISIBILITY"];
    return allowedMutations.includes(mutation.type);
  },
});

export default createStore({
  modules: {
    auth,
    customPanels,
    section,
  },
  plugins: [persist.plugin],
});
